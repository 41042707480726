import { createReducer } from 'redux-act'
import { changeTabBar, changeActivePanel, setTopicSetsEmpty } from '~/actions'

const defaultState = {
  activeTabBar: 'home',
  prevTabBar: 'home',
  activePanel: 'main',
  prevPanel: 'main',
  topicSetsIsEmpty: false,
}
const reducer = createReducer({}, defaultState)

reducer.on(changeTabBar, (state, activeTabBar) =>
  ({ ...state, activeTabBar, prevTabBar: state.activeTabBar }))

reducer.on(changeActivePanel, (state, activePanel) =>
  ({ ...state, activePanel, prevPanel: state.activePanel }))

reducer.on(setTopicSetsEmpty, (state, _) =>
  ({ ...state, topicSetsIsEmpty: true }))

export default reducer
export type State = typeof defaultState
