import React, { SFC, useEffect } from 'react'
import { connect } from 'react-redux'
import { Div, Button } from '@vkontakte/vkui'
import Icon36Done from '@vkontakte/icons/dist/36/done'
import Icon36Cancel from '@vkontakte/icons/dist/36/cancel'

import { IState } from '~/reducers'
import { changeTabBar, changeActivePanel, resetPayStatus } from '~/actions'

interface StateProps {
  status: IState['payStatus']['status']
  result: IState['payStatus']['result']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
  changeActivePanel: typeof changeActivePanel
  resetPayStatus: typeof resetPayStatus
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ payStatus: { status, result } }: IState) => ({ status, result }),
  { changeTabBar, changeActivePanel, resetPayStatus }
)

const BuyResult: SFC<Props> = ({ status, result, changeTabBar, changeActivePanel, resetPayStatus }) => {
  useEffect(() => { setTimeout(resetPayStatus, 200) })

  return (
    <>
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        margin: '2rem 0 0 0',
        textAlign: 'center',
        width: '100%',
        fontSize: '1.6rem',
        color: result
          ? 'var(--button_commerce_background)'
          : 'var(--field_error_border)',
      }}>
        { result ? <Icon36Done /> : <Icon36Cancel /> }
        { result ? <span>Успешно</span> : <span>Неудачно</span> }
      </div>
      <Div style={{ textAlign: 'center', lineHeight: '1.6rem' }}>
        { result
          ? <div>
              <p>Платеж принят. Ваши купоны создаются.</p>
              <p>Вы можете просмотреть их в разделе "Мои купоны"</p>
              <Button onClick={() => {
                changeTabBar('coupons')
                changeActivePanel('main')
              }}>Мои купоны</Button>
            </div>
          : <div>
              <p>Платеж не прошел. Попробуйте еще раз.</p>
              <p>Если ошибка повторяется – напишите администрации сообщества "Купикупон"</p>
              <br />
              <a href="https://vk.com/kupikupon" target="_blank" style={{ textDecoration: 'none' }}>
                <Button size='l'>Сообщество КупиКупон</Button>
              </a>
            </div>
        }
      </Div>
    </>
  )
}

export default withConnect(BuyResult)