import React, { useState, useEffect, useCallback, useRef, SFC } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import { getDealContainers } from '~/actions'
import { IState } from '~/reducers'
import { DealContainer } from '~/models/api'

import DealContainersList from '~/components/shared/DealContainersList'

interface OwnProps {
  category: string
  onChoose: (dc: DealContainer) => void
}

// TODO: Replace with selectors?
const mapStateToProps = ({ dealContainers: { entities, ids} }: IState) => ({ entities, ids })

const DealContainersCategoryList: SFC<OwnProps> = ({ onChoose, category }) => {
  // Create Paging State
  // TODO: Store page var for category globally in redux, because after unmount state will loose
  const [ page, setPage ] = useState(1)

  // Map state data
  const { entities, ids } = useMappedState(useCallback(mapStateToProps, []))
  const items = ids.map(id => entities[id])
  
  // Create actions
  const dispatch = useDispatch()
  const requestDealContainers = useCallback(() => { 
    setPage(page => page + 1)
    dispatch(getDealContainers({ category, page }))
  }, [ page ])

  // Create Loading state
  const [ isLoading, setIsLoading ] = useState(false)
  useEffect(() => setIsLoading(false), [ ids ])

  // Initial request deal Containers
  useEffect(() => requestDealContainers(), [])

  return <DealContainersList
    entities={items}
    onChoose={onChoose}
    isLoading={isLoading}
    moreDisabled={false}
    onMore={() => {
      setIsLoading(true)
      requestDealContainers()
    }}
  />
}

export default DealContainersCategoryList
