import { VKUserInfo, VkUserEmail, VkUserPhoneNumber, VkPayForm } from '../models/vk'

export default function callMethod(method: string, props: any, options?: { successType: string, failedType: string }) {
  return new Promise((resolve, reject) => {
    switch (method) {
      case 'VKWebAppInit':
        resolve()
        break;
      case 'VKWebAppGetUserInfo':
        resolve(<VKUserInfo>{
          id: 0,
          first_name: 'Macaulay',
          last_name: 'Tsukerman',
          sex: 1,
          city: {
            id: 0,
            title: 'Московский'
          },
          country: {
            id: 0,
            title: 'Russia'
          },
          timezone: 0,
          photo_100: '',
          photo_200: '',
        })
        break;
      case 'VKWebAppGetEmail':
        resolve(<VkUserEmail>{
          email: 'foo@bar.quiz',
          sign: 'somesign'
        })
        break;
      case 'VKWebAppGetPhoneNumber':
        resolve(<VkUserPhoneNumber>{
          phone_number: '+669676512197',
          sign: 'somesign'
        })
        break;
      case 'VKWebAppOpenPayForm':
        resolve(<VkPayForm>{
          status: true,
          transaction_id: '1',
          amount: '100',
          extra: 'extraString',
        })
        break;
      case 'VKWebAppAllowMessagesFromGroup':
        resolve({ result: true })
      case 'VKWebAppAllowNotifications':
        resolve({ enabled: true })
      case 'VKWebAppSetLocation':
        resolve({ location: location.hash })
    }
  })
}
