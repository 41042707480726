import React, { useEffect, SFC } from 'react'
import { connect } from 'react-redux'
import { Cell, List, View, Panel, Spinner, PanelHeader } from '@vkontakte/vkui'

import { requestCategories, changeTabBar, setActiveCategory, clearDealContainers } from '~/actions'
import { IState } from '~/reducers'

interface OwnProps {
  id: string
}

interface StateProps {
  categories: IState['categories']
  activePanel: IState['ui']['activePanel']
}

interface DispatchProps {
  requestCategories: typeof requestCategories
  changeTabBar: typeof changeTabBar
  setActiveCategory: typeof setActiveCategory
  clearDealContainers: typeof clearDealContainers
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ categories, ui: { activePanel } }: IState) => ({ categories, activePanel }),
  { requestCategories, changeTabBar, setActiveCategory, clearDealContainers }
)

const Categories: SFC<Props> = ({ id, activePanel, changeTabBar, setActiveCategory, categories, requestCategories }) => {
  useEffect(() => {
    clearDealContainers()
    requestCategories()
  }, [])

  return (
    <View id={id} activePanel={activePanel}>
      <Panel id='main'>
        <PanelHeader>Категории</PanelHeader>
          { categories.length <= 0
            ? <Spinner size='regular' />
            : <List>
              { categories.map(c =>
                <Cell
                  expandable
                  key={c.id}
                  onClick={() => {
                    setActiveCategory(c.slug)
                    changeTabBar('list')
                  }}>
                  {c.name}
                </Cell>
              ) }
            </List>
          }
      </Panel>
    </View>
  )
}

export default withConnect(Categories)
