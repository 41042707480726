import React, { ReactNode, SFC } from 'react'
import { HeaderButton, platform, IOS, PanelHeader, PanelHeaderContent } from '@vkontakte/vkui'
import { connect } from 'react-redux'

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'

import { IState } from '~/reducers'
import { changeTabBar } from '~/actions'

const osname = platform()

interface OwnProps {
  children: ReactNode
  noShadow?: boolean
  goBack?: () => void
}

interface StateProps {
  prevTabBar: IState['ui']['prevTabBar']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ ui: { prevTabBar } }: IState) => ({ prevTabBar }),
  { changeTabBar }
)

const PanelHeaderEnchanted: SFC<Props> = ({ children, goBack, changeTabBar, prevTabBar }) =>
  <PanelHeader
    left={
      <HeaderButton onClick={() => {
        goBack
          ? goBack()
          : changeTabBar(prevTabBar)
      }}>
        {osname === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
      </HeaderButton>
    }>
    <PanelHeaderContent>
      { children }
    </PanelHeaderContent>
  </PanelHeader>

export default withConnect(PanelHeaderEnchanted)
// export default ({ children, goBack }: Props) =>
//   <div
//     style={
//       osname === IOS
//         ? {
//           alignItems: 'center',
//           display: 'flex',
//           height: 44,
//           justifyContent: 'center',
//           left: 0,
//           paddingTop: 'env(safe-area-inset-top)',
//           position: 'fixed',
//           top: 0,
//           width: '100%',
//           zIndex: 3,
//           fontFamily: 'var(--font-ios)',
//           background: 'var(--control_foreground)',
//           color: 'var(--background_suggestions)',
//           textAlign: 'center',
//           fontWeight: 500,
//           fontSize: 17,
//           lineHeight: 43,
//           overflow: 'hidden'
//         }
//         : {
//           alignItems: 'center',
//           display: 'flex',
//           height: 56,
//           left: 0,
//           paddingTop: 'env(safe-area-inset-top)',
//           position: 'fixed',
//           top: 0,
//           width: '100%',
//           zIndex: 2,
//           fontFamily: 'var(--font-android)',
//           background: 'var(--control_foreground)',
//           color: 'var(--background_suggestions)',
//           textAlign: 'center',
//           fontWeight: 500,
//           fontSize: 20,
//           lineHeight: 56,
//           overflow: 'hidden'
//         }
//     }>
//     { goBack &&
//       <div
//         style={
//           osname === IOS
//             ? { minWidth: 42, paddingLeft: 4 }
//             : { minWidth: 58, paddingLeft: 4 }
//         }
//       >
//         <HeaderButton onClick={goBack}>
//           {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
//         </HeaderButton>
//       </div>
//     }
//     <div style={
//       osname === IOS
//         ? (goBack
//           ? { zIndex: 2, width: '100%', paddingRight: 42 }
//           : { zIndex: 2, width: '100%' }
//         )
//         : (goBack
//           ? { zIndex: 2 }
//           : { zIndex: 2, padding: '0 18px' }
//         )
//       } >
//       { children }
//     </div>
//   </div>
