import React, { SFC, ReactElement } from 'react'
import { connect } from 'react-redux'

import { IState } from '~/reducers'
import ErrorCityResolve from './views/messages/ErrorCityResolve'
import ErrorVkMessagesNotAllowed from './views/messages/ErrorVkMessagesNotAllowed'
import ErrorVkPaymentCancelled from './views/messages/ErrorVkPaymentCancelled'
import ErrorFetch from './views/messages/ErrorFetch'

interface OwnProps {
  alternate: ReactElement
}

interface StateProps {
  activeTabBar: IState['ui']['activeTabBar']
}

type Props = StateProps & OwnProps

const withConnect = connect<StateProps, {}, OwnProps>(
  ({ ui: { activeTabBar } }: IState) => ({ activeTabBar } as Props)
)

const SeparatedPages: SFC<Props> = ({ activeTabBar, alternate }) => {
  switch (activeTabBar) {
    case 'errorCityIsset':
      return <ErrorCityResolve id='errorCityIsset' statusText='В вашем городе мы пока не работаем' />
    case 'errorCityResolve':
      return <ErrorCityResolve id='errorCityResolve' statusText='Не удалось определить ваш город или мы в нём не работаем' />
    case 'errorVkMessagesNotAllowed':
      return <ErrorVkMessagesNotAllowed id='errorVkMessagesNotAllowed' />
    case 'errorVkPaymentCancelled':
      return <ErrorVkPaymentCancelled id='errorVkPaymentCancelled' />
    case 'errorFetchDealContainer':
      return <ErrorFetch id='errorFetchDealContainer' title='Произошла проблема с загрузкой выбранной акции' text='Пожалуйста, выберите другую акцию.' />
    default:
      return alternate
  }
}

export default withConnect(SeparatedPages)
