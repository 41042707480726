import { createReducer } from 'redux-act'
import { setCities, setCurrentCity } from '~/actions'
import { City } from '~/models/api'

const defaultCity = {
  id: 0,
  name: 'Москва',
  domain: 'moscow',
  seo_title: '',
  seo_description: '',
  seo_keywords: '',
  position: 0,
}

const defaultState = {
  entities: [],
  current: defaultCity
} as {
  entities: City[],
  current?: City
}

const reducer = createReducer({}, defaultState)

reducer.on(setCities, (state, entities) => ({ ...state, entities }))
reducer.on(setCurrentCity, (state, selected) => ({
  ...state,
  current: state.entities.find(({ name }) => name === selected) || defaultCity
}))

export default reducer
export type State = typeof defaultState
