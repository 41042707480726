import { createStore, applyMiddleware, compose, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { init, captureException } from 'sentry-cordova'

import { renderError } from './index'
import rootReducer, { IState, IAction } from './reducers'
import sagas from './sagas'

export const sagaMiddleware = createSagaMiddleware()

const configureStore = (): Store<IState, IAction> => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const middlewares = composeEnhancers(applyMiddleware(sagaMiddleware))
  const store = createStore<IState, IAction, void, void>(rootReducer, middlewares)
  const sagaTask = sagaMiddleware.run(sagas)

  init({ dsn: 'https://3a44f23a4d314439bc621b6657af0ad6@sentry.1pkk.ru/2' })
  
  sagaTask.toPromise().catch((err) => {
    renderError(err)
    captureException(err)
  })

  return store
}

export default configureStore()
