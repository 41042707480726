import React, { SFC, useEffect } from 'react'
import { connect } from 'react-redux'
import { View, Panel } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { changeTabBar, changeActivePanel, setActiveDealContainer, requestTopicSet } from '~/actions'

import PanelHeader from '~/components/shared/PanelHeader'
import DealContainersList from '~/components/shared/DealContainersList'
import { DealContainer, TopicSetFull } from '~/models/api'

interface OwnProps {
  id: string
}

interface StateProps {
  topicSet: TopicSetFull
  activeTopicSet: IState['activeData']['activeTopicSet']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
  changeActivePanel: typeof changeActivePanel
  setActiveDealContainer: typeof setActiveDealContainer
  requestTopicSet: typeof requestTopicSet
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ activeData: { activeTopicSet }, topicSets }: IState) =>
    ({ activeTopicSet, topicSet: topicSets[activeTopicSet as string] as TopicSetFull }),
  { changeTabBar, changeActivePanel, setActiveDealContainer, requestTopicSet }
)

const List: SFC<Props> = ({ id, topicSet, changeTabBar, changeActivePanel, setActiveDealContainer, activeTopicSet, requestTopicSet }) => {
  useEffect(() =>  { requestTopicSet(activeTopicSet as string) }, [])

  return (
    <View id={id} activePanel='main'>
      <Panel id='main'>
        <PanelHeader
          goBack={() => {
            changeTabBar('home')
          }} >
          Подборка { topicSet && `"${topicSet.short_title}"` }
        </PanelHeader>
        <DealContainersList
          entities={topicSet ? topicSet.deal_containers : []}
          onChoose={({ route }: DealContainer) => {
            setActiveDealContainer(route)
            changeTabBar('item')
            changeActivePanel('main')
          }}
          onMore={() => {}}
          isLoading={false}
          moreDisabled={true}
        />
      </Panel>
    </View>
  )
}

export default withConnect(List)
