import React from 'react'
import { Div, InfoRow } from '@vkontakte/vkui'

import { IState } from '~/reducers'

type Props = {
  coupon: IState['coupons'][any]
  requestCouponQr: (a: string) => void
  onChoose: (a: string) => void
}

const CouponsListItem = ({ coupon, requestCouponQr, onChoose }: Props) =>
  <div
    onClick={() => {
      let id = coupon.id.toString()
      requestCouponQr(id)
      onChoose(id)
    }}
  >
    <Div style={{ fontSize: '.8rem' }}>
      { coupon.title }
    </Div>
    <Div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
      <InfoRow title="Номер">
        { coupon.human_number }
      </InfoRow>
      <InfoRow title="Код купона">
        { coupon.code }
      </InfoRow>
      <InfoRow title="Секретный код">
        { coupon.secret }
      </InfoRow>
    </Div>
  </div>

export default CouponsListItem
