import { createReducer } from 'redux-act'
import { setCoupons, setCouponQr } from '../actions'
import { Coupon } from '../models/api'

export interface CouponExtended extends Coupon {
  qr?: string
}

const defaultState = {} as { [key: string]: CouponExtended }
const reducer = createReducer({}, defaultState)

const flatMapToObj = <T extends { [key: string]: any }, K extends keyof T>(key: K, arr: T[]): { K: T } | {} =>
  arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {})

reducer.on(setCoupons, (state, payload) => ({
  ...state,
  ...flatMapToObj('id', payload)
}))

reducer.on(setCouponQr, (state, [ id, qr ]) => ({
  ...state,
  [id]: { ...state[id], qr }
}))

export default reducer
export type State = typeof defaultState
