import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { Epic } from '@vkontakte/vkui'

import { IState } from '~/reducers'

import TabBar from './TabBar'
import Home from './views/home/Home'
import Item from './views/item/Item'
import List from './views/list/List'
import Categories from './views/categories/Categories'
import Coupons from './views/coupons/Coupons'
// import Map from './views/map/Map'
import Search from './views/search/Search'
import TopicSet from './views/topic/TopicSet'

interface Props {
  activeTabBar: IState['ui']['activeTabBar']
}

const withConnect = connect(
  ({ ui: { activeTabBar } }: IState) => ({ activeTabBar } as Props)
)

const EpicView: SFC<Props> = ({ activeTabBar }) =>
  <Epic
    activeStory={activeTabBar}
    tabbar={<TabBar />}>
    {/* <Root id='main' activeView={activeTabBar}> */}
      <Home id='home' />
      <Item id='item' />
      <List id='list' />
      <Categories id='categories' />
      <Coupons id='coupons' />
      {/* <Map id='onmap' /> */}
      <Search id='search' />
      <TopicSet id='topic' />
    {/* </Root> */}
  </Epic>

export default withConnect(EpicView)
