import connect from '@vkontakte/vkui-connect'
import devCallMethod from './vkDev'

import { VKUserInfo, VkUserEmail, VkUserPhoneNumber, VkPayForm } from '~/models/vk'

function callMethod(method: string, props: any) {
  return new Promise((resolve, reject) => {
    if (!connect.supports(method))
      return reject(`Method "${method}" is not supported`)

    const subs = connect.subscribe(({ detail: { type, data } }) => {
      switch (type) {
        case `${method}Failed`:
          reject(data)
          connect.unsubscribe(subs)
          break;
        case `${method}Result`:
          resolve(data)
          connect.unsubscribe(subs)
          break;
      }
    })

    connect.send(method, props)
    setTimeout(() => reject(Error(`VK Timeout error for method ${method}`)), 60000)
  })
}

const call = process.env.NODE_ENV === 'development'
  ? devCallMethod
  : callMethod

export const VKWebAppInit = () =>
  new Promise(resolve => {
    connect.send('VKWebAppInit', {})
    resolve()
  })

export const VKWebAppGetUserInfo = () =>
  call('VKWebAppGetUserInfo', {}) as Promise<VKUserInfo>

export const VKWebAppGetEmail = () =>
  call('VKWebAppGetEmail', {}) as Promise<VkUserEmail>

export const VKWebAppGetPhoneNumber = () =>
  call('VKWebAppGetPhoneNumber', {}) as Promise<VkUserPhoneNumber>
  
export const VKWebAppOpenPayForm = (props: any) =>
  call('VKWebAppOpenPayForm', props) as Promise<VkPayForm>
  
export const VKWebAppAllowMessagesFromGroup = (group_id: number) =>
  call('VKWebAppAllowMessagesFromGroup', { group_id, key: 'null' /* TODO: Gen key? */ }) as Promise<{ result: boolean }>
  
export const VKWebAppAllowNotifications = () =>
  call('VKWebAppAllowNotifications', {}) as Promise<{ enabled: boolean } | { error_type: string, error_data: {} }>

export const VKWebAppSetLocation = (location: string) =>
  // call('VKWebAppSetLocation', { location }) as Promise<{}> as unknown // TODO: check API
  new Promise(resolve => {
    connect.send('VKWebAppSetLocation', { location })
    resolve()
  })