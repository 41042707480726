import { createReducer } from 'redux-act'
import { setPromoBlocks } from '../actions'
import { PromoBlock } from '../models/api'

const defaultState = [] as PromoBlock[]
const reducer = createReducer({}, defaultState)

reducer.on(setPromoBlocks, (_, payload) => payload)

export default reducer
export type State = typeof defaultState
