import { call, put, select, takeEvery, fork } from 'redux-saga/effects'

import { City } from '~/models/api'
import { setCities, setCurrentCity } from '~/actions'
import { fetchJson } from './fetch'
import { selectCityByName } from './selectors'
import { setSessionValue } from '../services/sessionStorage'

export function* fetchCitiesSaga() {
  const [ response ]: [ { cities: City[] } ] = yield call(fetchJson, `cities`)
  if (response) yield put(setCities(response.cities))
}

function* cacheCity({ payload }) {
  setSessionValue('city', payload)
}

export function* resolveCurrentCity(cityName: string) {
  let cityIsset: string | void = yield select(selectCityByName, cityName)
  if (cityIsset) yield put(setCurrentCity(cityName))
  return Boolean(cityIsset)
}

export default function* () {
  yield takeEvery(setCurrentCity, cacheCity)
}
