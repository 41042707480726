import React, { SFC } from 'react'
import { View, Panel, PanelHeader, Div, FormStatus, Button } from '@vkontakte/vkui'

interface Props {
  id: string
}

const ErrorVkPaymentCancelled: SFC<Props> = ({ id }) =>
  <View id={id} activePanel="main">
    <Panel id="main">
      <PanelHeader>Ошибка покупки</PanelHeader>
      <Div>
        <FormStatus title="Повторите покупку" state="error">
          Оплата отменена
        </FormStatus>
        <Button onClick={() => window.location.reload()}>
          На главный экран
        </Button>
      </Div>
    </Panel>
  </View>

export default ErrorVkPaymentCancelled
