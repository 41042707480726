import { fork, put, call, take, select } from 'redux-saga/effects'
import { selectCurrentCity } from './selectors'
import { fetchJson } from './fetch'
import { requestCategories, setCategories } from '~/actions'
import { Category } from '~/models/api'

function* fetchCategoriesSaga() {
  yield take(requestCategories)
  const currentCity = yield select(selectCurrentCity)
  const [ response ]: [ { categories: Category[] } ] = yield call(fetchJson, `categories?city_name=${currentCity}`)
  if (response) yield put(setCategories(response.categories))
}

export default function* () {
  yield fork(fetchCategoriesSaga)
}
