import React, { SFC, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { View, Panel, FixedLayout, Search, PanelHeader } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { requestSearchDealContainers, setActiveDealContainer, changeTabBar, changeActivePanel } from '~/actions'

import DealContainersList from '~/components/shared/DealContainersList'
import { DealContainer } from '~/models/api'

interface OwnProps {
  id: string
}

interface StateProps {
  entities: IState['dealContainers']['entities'],
  search: IState['dealContainers']['search'],
  loading: IState['dealContainers']['loading'],
}

interface DispatchProps {
  requestSearchDealContainers: typeof requestSearchDealContainers
  setActiveDealContainer: typeof setActiveDealContainer
  changeTabBar: typeof changeTabBar
  changeActivePanel: typeof changeActivePanel
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ dealContainers: { entities, search, loading } }: IState) =>
    ({ entities, search, loading }),
  { requestSearchDealContainers, setActiveDealContainer, changeTabBar, changeActivePanel }
)

const List: SFC<Props> = ({ id, entities, loading, search, setActiveDealContainer, requestSearchDealContainers, changeTabBar, changeActivePanel }) => {
  let items = search.map(route => entities[route]).filter(a => a)
  let [alreadySearched, setAlreadySearched] = useState(false)
  let onAlreadySearch = useCallback(() => {
    setTimeout(() => setAlreadySearched(true), 500)
  }, [])

  return (
    <View id={id} activePanel="search">
      <Panel id="search">
        <PanelHeader noShadow>Поиск акций</PanelHeader>
        <FixedLayout vertical='top'>
          <Search
            autoFocus
            onChange={(value: string) => {
              onAlreadySearch()
              requestSearchDealContainers(value)
            }}
          />
        </FixedLayout>
        <div style={{ marginTop: 48 }}>
          <DealContainersList
            entities={items}
            onChoose={({ route }: DealContainer) => {
              setActiveDealContainer(route)
              changeTabBar('item')
              changeActivePanel('main')
            }}
            onMore={() => {}}
            moreDisabled={true}
            disableSpinner={!alreadySearched}
            isLoading={loading}
          />
        </div>
      </Panel>
    </View>
  )
}

export default withConnect(List)
