import { IState } from '~/reducers'

export const selectUserInfo = ({ user }: IState) => user
export const selectAuthToken = ({ user: { authToken } }: IState) => authToken
export const selectCurrentCity = ({ cities: { current } }: IState) => current ? current.domain : 'moscow'
export const selectCityByName = ({ cities: { entities } }: IState, name: string) => entities.find(c => c.name === name)
export const selectActiveDealContainer = ({ activeData: { activeDealContainer } }: IState) => activeDealContainer
export const selectActiveTopicSet = ({ activeData: { activeTopicSet } }: IState) => activeTopicSet
export const selectActiveCoupon = ({ activeData: { activeCoupon } }: IState) => activeCoupon
export const selectActiveCategory = ({ activeData: { activeCategory } }: IState) => activeCategory
