import { createReducer } from 'redux-act'
import { combineReducers } from 'redux'

import { VKUserInfo } from '../models/vk'
import { setVkUser } from '../actions'

const defaultState = {
  city: {}
} as VKUserInfo
const user = createReducer({}, defaultState)

user.on(setVkUser, (_, payload) => payload)

export default combineReducers({ user })
export type State = { user: typeof defaultState }
