import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { Spinner, Div } from '@vkontakte/vkui'
import { IState } from '~/reducers'

interface StateProps {
  status: IState['payStatus']['status']
}

interface OwnProps {
  onComplete: () => void
}

type Props = StateProps & OwnProps

const withConnect = connect<StateProps, {}, OwnProps>(
  ({ payStatus: { status } }: IState) => ({ status })
)

const DealBuyProcess: SFC<Props> = ({ status, onComplete }) => {
  // TODO: rewrite it!
  if (status === 'Успешно' || status === 'Неудачно') onComplete()
  return (
    <Div style={{ height: 100, textAlign: 'center' }}>
      <Spinner />
      <div>{ status }</div>
    </Div>
  )
}

export default withConnect(DealBuyProcess)