import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { Tabbar, TabbarItem } from '@vkontakte/vkui'

import Icon28Newsfeed from '@vkontakte/icons/dist/28/newsfeed'
import Icon28Menu from '@vkontakte/icons/dist/28/menu'
import Icon28SearchOutline from '@vkontakte/icons/dist/28/search_outline'
import Icon28Place from '@vkontakte/icons/dist/28/place'
import Icon28Attachments from '@vkontakte/icons/dist/28/attachments'

import { changeTabBar, changeActivePanel } from '../actions'
import { IState } from '../reducers'

interface StateProps {
  activeTabBar: IState['ui']['activeTabBar']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
  changeActivePanel: typeof changeActivePanel
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ ui: { activeTabBar } }: IState) => ({ activeTabBar }),
  { changeTabBar, changeActivePanel }
)

const TabBar: SFC<Props> = ({ activeTabBar, changeTabBar, changeActivePanel }) => {
  const change = (tabBar: string) => {
    changeTabBar(tabBar)
    changeActivePanel('main')
  }

  return (
    <Tabbar>
      <TabbarItem
        onClick={() => change('home')}
        selected={activeTabBar === 'home'}>
        <Icon28Newsfeed />
      </TabbarItem>
      <TabbarItem
        onClick={() => changeTabBar('search')}
        selected={activeTabBar === 'search'}>
        <Icon28SearchOutline />
      </TabbarItem>
      <TabbarItem
        onClick={() => change('categories')}
        selected={activeTabBar === 'categories'}>
        <Icon28Menu />
      </TabbarItem>
   
      <TabbarItem
        onClick={() => change('coupons')}
        selected={activeTabBar === 'coupons'}>
        <Icon28Attachments />
      </TabbarItem>
    </Tabbar>
  )
}

export default withConnect(TabBar)
