import { createReducer } from 'redux-act'
import { setMapLoadingState, setActiveSpotsForMap } from '~/actions'
import { DealContainerSpot, DealContainerFull } from '~/models/api'

const defaultState = {
  isLoading: false,
  activeSpots: []
} as {
  isLoading: boolean
  activeSpots: {
    spot: DealContainerSpot,
    dealContainer: DealContainerFull
  }[]
}

const reducer = createReducer({}, defaultState)

reducer.on(setMapLoadingState, (state, isLoading) =>
  ({ ...state, isLoading }))

reducer.on(setActiveSpotsForMap, (state, activeSpots) =>
  ({ ...state, activeSpots }))

export default reducer
export type State = typeof defaultState
