import { call, take, put, fork, select } from 'redux-saga/effects'

import { selectActiveDealContainer, selectActiveTopicSet, selectActiveCoupon, selectActiveCategory } from './selectors'
import { setActiveDealContainer, changeTabBar, changeActivePanel, setActiveTopicSet, setActiveCoupon, requestCouponQr, setCoupons, setActiveCategory } from '~/actions'
import { VKWebAppSetLocation } from '~/services/vk'
import { PayloadOfAction } from '~/actions/utils'
import { trackPageView } from '~/services/gtm'

export default function* () {
  yield call(getInitialHash)
  yield fork(watchForTabsChangesAndSetHash)
}

function* getInitialHash() {
  let { hash } = window.location
  trackPageView(hash.replace('#', '/'))
  yield call(parseHashStr, hash)
}

function* watchForTabsChangesAndSetHash() {
  while (true) {
    let { payload }: PayloadOfAction<typeof changeTabBar> = yield take(changeTabBar)
    let hash = payload

    if (hash === 'item') {
      let activeDC = yield select(selectActiveDealContainer)
      hash = `deals/${activeDC}`
    }
    if (hash === 'topic') {
      let activeTopic = yield select(selectActiveTopicSet)
      hash = `topic_sets/${activeTopic}`
    }
    if (hash === 'coupons') {
      let activeCoupon = yield select(selectActiveCoupon)
      activeCoupon
        ? hash = `coupons/${activeCoupon}`
        : 'coupons'
    }
    if (hash === 'list') {
      let activeCategoty = yield select(selectActiveCategory)
      hash = `categories/${activeCategoty}`
    }
    window.location.hash = `#${hash}`
    trackPageView(hash)
    yield fork(VKWebAppSetLocation, hash)
  }
}

function* parseHashStr(hash: string) {
  let dealRegexp = /deals\/(.+)/g
  let [ , dcId ] = dealRegexp.exec(hash) || [ , null ]
  if (dcId) return yield call(goToDealContainerPage, dcId)

  let topicSetsRegexp = /topic_sets\/(.+)+/g
  let [ , tsId ] = topicSetsRegexp.exec(hash) || [ , null ]
  if (tsId) return yield call(goToTopicSetPage, tsId)

  let couponsRegexp = /coupons\/(.+)+/g
  let [ , cId ] = couponsRegexp.exec(hash) || [ , null ]
  if (cId) return yield call(goToCouponPage, cId)

  let categoriesRegexp = /categories\/(.+)/g
  let [ , catId ] = categoriesRegexp.exec(hash) || [ , null ]
  if (catId) return yield call(goToCategoryPage, catId)

  let pagesRegexp = /coupons|onmap/
  let [ pageId ] = pagesRegexp.exec(hash) || [ null ]
  if (pageId) return yield call(goToPage, pageId)
}

function* goToDealContainerPage(id: string) {
  yield put(setActiveDealContainer(id))
  yield put(changeTabBar('item'))
}

function* goToTopicSetPage(id: string) {
  yield put(setActiveTopicSet(id))
  yield put(changeTabBar('topic'))
}

function* goToPage(id: string) {
  yield put(changeTabBar(id))
  yield put(changeActivePanel('main'))
}

function* goToCouponPage(id: string) {
  yield put(setActiveCoupon(id))
  yield put(changeTabBar('coupons'))
  yield take(setCoupons) // wait for coupons first
  yield put(requestCouponQr(id))
}

function* goToCategoryPage(id: string) {
  yield put(setActiveCategory(id))
  yield put(changeTabBar('list'))
}
