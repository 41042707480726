import React from 'react'
import { View, Panel, PanelHeader, Group, Div } from '@vkontakte/vkui'

import MainBanner from './parts/MainBanner'
import HotDeals from './parts/HotDeals'
import TopicSets from './parts/TopicSets'

export default ({ id }: { id: string }) =>
  <View id={ id } activePanel='main'>
    <Panel id='main'>
      <PanelHeader>КупиКупон</PanelHeader>
      <Div style={{ paddingBottom: 0 }}>
        <MainBanner />
      </Div>
      <Group title="Самые горячие акции">
        <HotDeals />
      </Group>
      <TopicSets />
    </Panel>
  </View>
