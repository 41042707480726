declare global {
  interface Window {
    DG: DG | any
  }
}

interface DG {
  then(db: () => void): Promise<void>
  plugin(path: string): Promise<void>
  map(container: string, options: { center: [ number, number ], zoom: number }): Map
  marker(coords: [ number, number ]): Marker
  markerClusterGroup(): ClusterGroup
  popup(options?: { mixWidth: number, maxWidth: number }): Popup
}

interface Map {
  on(event: string, cb: () => void): void
  addLayer(layer: ClusterGroup): void
  getBounds(): Bounds
  openPopup(popup: Popup): void
  setView(coords: [ number, number ]): void
  locate(options: { watch: boolean, setView: boolean }): Locate
  getCenter(): Point
}

interface Point {
  // TODO: Fix it?
  latitude: number
  longitude: number
  lat: number
  lng: number
}

interface Locate {
  on(event: string, cb: (point: Point) => void): Locate
}

export interface Marker {
  addTo(group: ClusterGroup): Marker
  on(event: string, cb: () => void): Function
  off(event: string, cb: Function): void
  bindPopup(popup: Popup): Marker
  openPopup(): Marker
}

interface ClusterGroup {

}

interface Popup {
  setLatLng(coords: [ number, number ] | Point): Popup
  setHeaderContent(content: string): Popup
  setContent(content: string): Popup
  openOn(layer: Map): Popup
}

export interface Bounds {
  getNorthEast(): Point
  getSouthWest(): Point
}

interface Props {
  container: string
}

export default class TwoGis {
  private dg: DG
  private container: string
  private isReady = false
  public map: Map
  public markers: any

  constructor({ container }: Props) {
    this.checkLibIsset()
      .then(() => {
        this.dg = window.DG
        this.container = container
        this.dg.then(() => this.start())
      })
  }

  protected checkLibIsset() {
    return new Promise(resolve => {
      'DG' in window
        ? resolve()
        : setTimeout(() => this.checkLibIsset().then(resolve), 100)
    })
  }

  protected async start() {
    await this.dg.plugin('https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js')
    this.map = this.dg.map(this.container, {
      'center': [ 55.75222, 37.61556 ],
      'zoom': 13
    })
    this.markers = this.dg.markerClusterGroup()
    this.map.addLayer(this.markers)
    // map.fitBounds(markers.getBounds())
    this.isReady = true
    return true
  }

  public onReady = () => new Promise(resolve => {
    let check = () => setTimeout(() => {
      this.isReady ? resolve() : check()
    }, 50)
    check()
  })

  public onMoveEnd = (cb: (bounds: Bounds) => void) =>
    this.map.on('moveend', () => cb(this.map.getBounds()))

  public createMarker(coords: [ number, number ]) {
    let marker = this.dg.marker(coords)
    marker.addTo(this.markers)
    return marker
  }

  public createPopup(coords: [ number, number ]) {
    let { map } = this
    let popup = this.dg.popup({
        mixWidth: 300,
        maxWidth: 500
      })
      .setContent(`<div style="width:120px;">Загружаем...</div>`)

    if (coords)
      popup.setLatLng(coords)

    return {
      getPopup() {
        return popup
      },
      openOnMap() {
        popup.openOn(map)
        return popup
      },
      setContent(data: { id: number, title: string, hours: string, phone: string }) {
        popup
          .setHeaderContent(data.title)
          .setContent(`
            <p>${data.hours}</p>
            <p>
              <ul style="margin:0;padding:0;">
                ${data.phone && data.phone.split(' ').map(p => `<li>${p}</li>`)}
              </ul>
            </p>
            <p>
              <a href="#deal/${data.id}" style="
                display: block;
                text-decoration: none;
                color: white;
                padding: .6rem;
                border: 1px solid white;
                border-radius: 4px;
                text-align: center;
                margin-top: 1rem;
                font-weight: bold;
              ">Открыть акцию<a>
            </p>
          `)
      }
    }
  }

  public locate() {
    this.map.locate({ setView: true, watch: true })
      .on('locationfound', (e: Point) =>
          this.dg.marker([ e.latitude, e.longitude ]).addTo(this.markers))

      .on('locationerror', () => {
        this.dg.popup()
          .setLatLng(this.map.getCenter())
          .setContent('Доступ к определению местоположения отключён')
          .openOn(this.map)
      })
  }
}