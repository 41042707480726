import { put, call, takeEvery, select } from 'redux-saga/effects'
import { fetchJson } from './fetch'
import { requestTopicSet, requestTopicSets, setTopicSets, setTopicSetsEmpty, updateTopicSet } from '~/actions'
import { TopicSet, TopicSetFull } from '~/models/api'
import { selectCurrentCity } from './selectors'

function* fetchTopicSetsSaga() {
  const currentCity = yield select(selectCurrentCity)
  const [ response ]: [ { topic_sets: TopicSet[] } ] = yield call(fetchJson, `topic_sets?city_name=${currentCity}`)
  if (response && response.topic_sets.length > 0)
    yield put(setTopicSets(response.topic_sets))
  else
    yield put(setTopicSetsEmpty())
}

function* fetchTopicSetSaga({ payload: slug }: { payload: string }) { // TODO: typeof action paylaod
  const currentCity = yield select(selectCurrentCity)
  const [ response ]: [ { topic_set: TopicSetFull } ] = yield call(fetchJson, `topic_sets/${slug}?city_name=${currentCity}`)
  if (response) yield put(updateTopicSet(response.topic_set))
}

export default function* () {
  yield takeEvery(requestTopicSet, fetchTopicSetSaga)
  yield takeEvery(requestTopicSets, fetchTopicSetsSaga)
}
