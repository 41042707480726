import React, { SFC } from 'react'
import { Group, List, Cell } from '@vkontakte/vkui'

import { DealContainerFull, Deal, DealGroup } from '~/models/api'

interface OwnProps {
  dealContainer: DealContainerFull
  onSelectDeal: (deal: Deal) => void
}

type Props = OwnProps

const filterByDealGroup = ({ id }: DealGroup) =>
  ({ deal_group_id }: Deal) =>
    deal_group_id === id

const getItem = (selectDeal: Props['onSelectDeal']) =>
  (deal: Deal) =>
    <Cell
      className='dealOption'
      multiline
      expandable
      key={deal.id}
      onClick={() => selectDeal(deal)}>
      {deal.title}
    </Cell>

const DealsOption: SFC<Props> = ({ dealContainer, onSelectDeal }) => {
  const { deal_groups: dealGroups, deals } = dealContainer
  const ListItem = getItem(onSelectDeal)

  return dealGroups.length > 0
    ? <>{dealGroups.map(group => (
        <Group title={ group.name }>
          <List>
            { deals
              .filter(filterByDealGroup(group))
              .map(ListItem) }
          </List>
        </Group>
      ))}</>
    : <Group>
      <List>
        { deals.map(ListItem) }
      </List>
    </Group>
}

export default DealsOption
