import { eventChannel } from 'redux-saga'
import { all, call, fork, take, put, takeLatest, delay } from 'redux-saga/effects'

import TwoGis, { Bounds, Marker } from '~/services/twoGis'
import { initGlobalMap, setMapLoadingState, setActiveSpotsForMap } from '~/actions'
import { DealContainerFull, DealContainerSpot } from '~/models/api'
import { fetchJson } from '~/sagas/fetch'

type Id = number
type Coordinates = [ number, number ]
type DealContainerIds = number[]

interface Spot {
  id: Id
  coordinates: Coordinates
  deal_container_ids: DealContainerIds
}

function makeOnMoveEndChannel(listener: (cb: (bounds: Bounds) => void) => void) {
  return eventChannel(emitter => {
    listener(emitter)
    return () => {}
  })
}

function makeOnClickChannel(marker: Marker) {
  return eventChannel(emitter => {
    let handler = marker.on('click', () => emitter({}))
    return () => marker.off('click', handler)
  })
}

function* fetchDealContainers(spotId: number, dcId: number) {
  try {
    let [ response, error ] = yield call(fetchJson, `deal_containers/${dcId}`)
    if (error) throw error
    let { deal_container: dealContainer } = response as { deal_container: DealContainerFull }
    let spot = dealContainer.spots.find(s => s.id === spotId) as DealContainerSpot
    return { spot, dealContainer }
  } catch (err) {
    console.log(err)
    return {}
  }
}

function* handleSpotEntity(twoGis: TwoGis, spotId: number, coords: [ number, number ], dcids: number[]) {
  let marker = twoGis.createMarker(coords)
  let chan = yield call(makeOnClickChannel, marker)

  while (true) {
    yield take(chan)
    let mapSpots: { spot: DealContainerSpot, dealContainer: DealContainerFull }[] = yield all(dcids.map(dcId => call(fetchDealContainers, spotId, dcId)))
    yield put(setActiveSpotsForMap(mapSpots))
  }
}

function makeFetchSpotsSaga(twoGis: TwoGis) {
  let spotsMap = new Map<Id, [ Coordinates, DealContainerIds ]>()
  let alreadyOnMap = new Set<number>()

  return function* fetchSpotsSaga(bounds: Bounds) {
    try {
      yield put(setMapLoadingState(true))

      let ne = bounds.getNorthEast()
      let sw = bounds.getSouthWest()
      let [ response, error ] = yield call(fetchJson, `spots/for_maps?sw=${sw.lat},${sw.lng}&ne=${ne.lat},${ne.lng}`)
      if (error) throw error
      let { features } = response as { features: Spot[] }
      features.map(({ id, coordinates, deal_container_ids }) =>
        spotsMap.set(id, [ coordinates, deal_container_ids ]))

      yield put(setMapLoadingState(false))

      let spotsMapEntries = Array.from(spotsMap.entries())
      yield all(spotsMapEntries.map(([ spotId, [ coords, dcids ] ]) => {
        if (!alreadyOnMap.has(spotId)) {
          alreadyOnMap.add(spotId)
          return fork(handleSpotEntity, twoGis, spotId, coords, dcids)
        } else {
          return false
        }
      }))
    } catch (err) {
      console.log(err)
    }
  }
}

export default function* twoGisSaga() {
  yield take(initGlobalMap)
  yield delay(50) // TODO: Dirty fix Error Map container not found.

  let twoGis = new TwoGis({ container: 'map' })
  yield call(twoGis.onReady)
  twoGis.locate()

  let onMoveEndChan = yield call(makeOnMoveEndChannel, twoGis.onMoveEnd)
  let fetchSpotSaga = makeFetchSpotsSaga(twoGis)

  yield takeLatest(onMoveEndChan, fetchSpotSaga)
  yield call(fetchSpotSaga, twoGis.map.getBounds())
}