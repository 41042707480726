import React from 'react'

export default ({ error }: { error: Error }) =>
  <div style={{
    background: '#5181b8',
    width: '100wv',
    height: '100vh',
    color: '#fff',
    fontFamily: 'Arial',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column nowrap',
  }}>
    <style dangerouslySetInnerHTML={{__html: `
      body { margin: 0px; }
    `}} />
    <h1 style={{
      fontWeight: 'normal',
      fontSize: '3rem',
      margin: 0
    }}>
      Произошла ошибка
    </h1>
    <h2 style={{
      fontWeight: 'normal',
      fontSize: '1rem' 
    }}>
      Мы обязательно исправим. Попробуйте пока перейти на главный экран.
    </h2>
    <button
      onClick={() => window.location.reload()}
      style={{
        color: '#fff',
        background: 'none',
        border: '1px solid #fff',
        padding: '20px 30px',
        textDecoration: 'none',
        marginTop: 20,
        borderRadius: 2,
        fontSize: '1.2rem'
      }}>
      На главный экран
    </button>
    <details style={{
      position: 'absolute',
      bottom: 0,
      background: '#fff',
      color: '#999',
      left: 0,
      width: '100vw',
    }}>
      <summary></summary>
      <code>{ error.stack }</code>
    </details>
  </div>
