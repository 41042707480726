import { createReducer } from 'redux-act'

import { setAuthToken, updateUserinfo } from '../actions'

const defaultState = { authToken: null as null | string, isPhoneSet: false, isEmailSet: false }
const user = createReducer({}, defaultState)

user.on(setAuthToken, (state, authToken) => ({ ...state, authToken }))
user.on(updateUserinfo, (state, payload) => ({ ...state, ...payload }))

export default user
export type State = typeof defaultState
