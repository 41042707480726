import { combineReducers } from 'redux'
import { StateType, ActionType } from 'typesafe-actions'

import * as actions from '~/actions'

import activeData from './activeData'
import categories from './categories'
import cities from './cities'
import coupons from './coupons'
import dealContainers from './dealContainers'
import map from './map'
import payStatus from './payStatus'
import promoBlocks from './promoBlocks'
import topicSets from './topicSets'
import ui from './uiState'
import user from './user'
import vk from './vk'

const rootReducer = combineReducers({
  activeData,
  categories,
  cities,
  coupons,
  dealContainers,
  map,
  payStatus,
  promoBlocks,
  topicSets,
  ui,
  user,
  vk,
})

export type IState = StateType<typeof rootReducer>
export type IAction = ActionType<typeof actions>

export default rootReducer
