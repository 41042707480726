import TagManager from 'react-gtm-module'

interface dataLayerEvent {
  event: string
}

declare global {
  interface Window {
    dataLayer: dataLayerEvent[]
  }
}

const getDataLayer = () => window.dataLayer || (window.dataLayer = [])

export const initTagManager = () =>
  TagManager.initialize({ gtmId: 'GTM-T6JN3CQ' })

export const trackPageView = (url: string) =>
  getDataLayer().push({ event: 'pageview', url } as dataLayerEvent)

export const trackAddToCart = (deal: string, quantity: number) =>
  getDataLayer().push({ event: 'add_to_cart', deal, quantity } as dataLayerEvent)

export const trackPaymentStart = () =>
  getDataLayer().push({ event: 'payment_start' } as dataLayerEvent)

export const trackPurchase = (amount: number) =>
  getDataLayer().push({ event: 'purchase', amount } as dataLayerEvent)