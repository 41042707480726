export let getSessionValue = (key: string) => {
  try {
    return sessionStorage.getItem(key)
  } catch (err) {
    console.error(err)
    return null
  }
}
export let setSessionValue = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value)
  } catch (err) {
    console.error(err)
  }
}
