import React, { useState, SFC } from 'react'
import { Div, Select, Group, Button } from '@vkontakte/vkui'

import { Deal } from '~/models/api'

interface Props {
  activeDeal: Deal
  onRequestBuy: (params: { count: number }) => void
}

const DealBuyOptions: SFC<Props> = ({ activeDeal, onRequestBuy }) => {
  const [ dealsCount, setDealsCount ] = useState(1)

  return (
    <>
      <Group title='Ваш купон'>
        <Div>{activeDeal.title}</Div>
      </Group>
      <Group title='К оплате'>
        <Div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.1rem' }}>
          <Select
            onChange={e => setDealsCount(parseInt(e.target.value))}
            className='optionSelectCount'
            style={{ minWidth: '30%' }}
          >
            {[...Array(32)].map((_, i) => (
              <option key={i+1} value={i+1}>{i+1}</option>
            ))}
          </Select>
          <div style={{ paddingLeft: '1rem', width: '40%' }}>
            x {activeDeal.cost} руб
          </div>
          <div>
            <strong>{activeDeal.cost * dealsCount} руб</strong>
          </div>
        </Div>
      </Group>

      <Div style={{ textAlign: 'center' }}>
        20% суммы покупки<br/>
        будет возвращено кешбэком
      </Div>

      <Div>
        <Button
          size='xl'
          level='primary'
          onClick={() => onRequestBuy({ count: dealsCount })} >
          Оплатить
        </Button>
      </Div>
    </>
  )
}

export default DealBuyOptions
