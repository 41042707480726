import { fork, put, call, take, select } from 'redux-saga/effects'
import { fetchJson } from './fetch'
import { requestPromoBlocks, setPromoBlocks } from '~/actions'
import { selectCurrentCity } from './selectors'
import { PromoBlock } from '../models/api'

function* fetchPromoBlocksSaga(id: string | number) {
  while (true) {
    yield take(requestPromoBlocks)
    const currentCity = yield select(selectCurrentCity)
    const [ response ]: [ { promo_blocks: PromoBlock[] } ] = yield call(
      fetchJson,
      `promo_blocks?promo_container_ids=${id}&city_name=${currentCity}`
    )
    if (response) yield put(setPromoBlocks(response.promo_blocks))
  }
}

export default function* () {
  yield fork(fetchPromoBlocksSaga, 38)
}
