import { fork, put, call, select, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { fetchJson } from './fetch'
import { selectCurrentCity } from './selectors'
import { DealContainer } from '~/models/api'
import { captureEvent } from 'sentry-cordova'

import {
  getDealContainers,
  setDealContainers,
  addDealContainers,
  requestSearchDealContainers,
  setSearchResultsDealContainers,
  setDealContainersLoading
} from '../actions'

function* fetchDealContainersListSaga() {
  let lastCategory = null as string | null

  yield takeEvery(getDealContainers, function* ({ payload }) {
    const { category, page } = payload
    const currentCity = yield select(selectCurrentCity)

    const [ response ] = yield call(
      fetchJson,
      `deal_containers?category_path=${category}&page=${page}&city_name=${currentCity}&subway_id=0&query=&filter=`
    )

    const updAction = lastCategory === category ? addDealContainers : setDealContainers
    lastCategory = category

    yield put(updAction(response.deal_containers))
  })
}

function* searchDealContainersSaga({ payload: request }: { payload: string }) {
  yield delay(500)
  const currentCity = yield select(selectCurrentCity)
  
  yield put(setDealContainersLoading(true))
  const [ response, error ]: [ { deal_containers: DealContainer[] }, Error ] =
    yield call(fetchJson, `deal_containers?city_name=${currentCity}&query=${request}`)
  yield put(setDealContainersLoading(false))
  
  if (error) console.log(error)
  else if (response) {
    let { deal_containers } = response
    yield put(setDealContainers(deal_containers))
    yield put(setSearchResultsDealContainers(deal_containers))
  } else {
    captureEvent({ message: 'Can\'t get response', extra: { request, response, error } })
  }
}

export default function* () {
  yield fork(fetchDealContainersListSaga)
  yield takeLatest(requestSearchDealContainers as any, searchDealContainersSaga)
}
