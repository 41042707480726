import { put, call, select, takeEvery, delay, fork } from 'redux-saga/effects'
import { captureEvent } from 'sentry-cordova'

import { fetchJson } from './fetch'
import { selectUserInfo } from './selectors'
import { IState } from '~/reducers'
import { resolveCurrentCity } from './cities'
import { PayloadOfAction } from '~/actions/utils'
import { urlPropsToObject } from '~/helpers/urlHelpers'
import { trackPaymentStart, trackPurchase } from '~/services/gtm'
import { getSessionValue } from '~/services/sessionStorage'

import { requestUpdateUser } from './user'

import {
  VKWebAppInit,
  VKWebAppGetUserInfo,
  VKWebAppGetPhoneNumber,
  VKWebAppGetEmail,
  VKWebAppOpenPayForm,
  VKWebAppAllowMessagesFromGroup,
  // VKWebAppAllowNotifications,
} from '~/services/vk'

import {
  changeTabBar,
  requestBuyDeal,
  resetPayStatus,
  setAuthToken,
  setPaymentStatus,
  setPayStatus,
  setVkUser,
  updateUserinfo,
} from '~/actions'

import {
  VkTransaction,
  VkUserEmail,
  VKUserInfo,
  VKUserInitialParams,
  VkUserPhoneNumber,
} from '~/models/vk'

import {
  CartOrderDealPostResponse,
  VkConnectSession,
  VkConnectSessionAddContacts,
  VkPayPaymentParams,
  OrderDeal,
  Payment,
} from '~/models/api'

export default function* vkPaySaga() {
  const { userId } = yield call(initialAuthorizationSaga)
  const watchForRequestBuyDeal = makeWatchForRequestBuyDeal(userId)

  yield takeEvery(requestBuyDeal, watchForRequestBuyDeal)
  yield takeEvery(resetPayStatus, watchForResetPayStatus)
}

function* watchForResetPayStatus() {
  yield put(setPayStatus('И снова здравствуйте! Пару секунд...'))
}

const makeWatchForRequestBuyDeal = (userId: number) =>
  function* watchForRequestBuyDeal({ payload: { id, num } }: PayloadOfAction<typeof requestBuyDeal>) {
    yield call(askForAllowRights)
    const { authToken, isEmailSet, isPhoneSet }: IState['user'] = yield select(selectUserInfo)

    if (!authToken)
      throw Error('authToken is not set')

    if (!isEmailSet && !isPhoneSet)
      yield call(requestAdditionalInfoSaga, authToken, userId)
  
    trackPaymentStart()

    const vkPayParams: VkPayPaymentParams = yield call(requestVKPayParamsSaga, authToken, id, num)
    captureEvent({ message: `vkPayParams`, extra: vkPayParams })

    const result: VkTransaction = yield call(vkSendPayFormSaga, vkPayParams)
    captureEvent({ message: `vkPayResult`, extra: result })

    if (!result) return yield put(changeTabBar('errorVkPaymentCancelled')) //check !result.status for dev

    yield put(setPayStatus('Проверяем ваш платеж'))
    const status: boolean = yield call(apiCheckPaymentId, authToken, vkPayParams.payment_id, 5000, 12)

    if (status) trackPurchase(parseFloat(result.amount))

    yield put(setPayStatus(status ? 'Успешно' : 'Неудачно'))

    try {
      yield put(setPaymentStatus(JSON.parse(vkPayParams.params.data)))
    } catch (err) {
      console.log({ err })
      captureEvent({ message: `Can't parse transaction`, extra: result })
    }
  }

function* askForAllowRights() {
  const { result } = yield call(VKWebAppAllowMessagesFromGroup, 16310088)
  if (!result) return yield put(changeTabBar('errorVkMessagesNotAllowed'))

  // const { enabled } = yield call(VKWebAppAllowNotifications)
  // if (!enabled) throw Error('User didn\'t allow to send messages from group')
}

function* initialAuthorizationSaga() {
  yield put(setPayStatus('И снова здравствуйте! Пару секунд...'))
  const [ user, initialProps ]: [ VKUserInfo, VKUserInitialParams ] = yield call(vkPayAuthSaga)
  if (!user || !initialProps) throw Error('Can\'t auth in VK service')
  else captureEvent({ message: `vkPayUserAuthProps`, extra: { user, initialProps } })

  const cityTitle = user.city && user.city.title
  const cachedCity = getSessionValue('city')
  const cityName = cityTitle || cachedCity

  if (cityName) {
    let isCityIsset: boolean = yield call(resolveCurrentCity, cityName)
    if (!isCityIsset) yield put(changeTabBar('errorCityIsset'))
  } else 
    yield put(changeTabBar('errorCityResolve'))

  yield put(setPayStatus('Бережно проверяем ваши данные'))
  const authResult = yield call(apiConnectSessionSaga, initialProps)
  const [ { authToken, isEmailSet, isPhoneSet }, authError ] = authResult

  if (authError) throw Error(`Can't auth on API with error: ${authError.message}`)
  if (authToken) yield put(setAuthToken(authToken))
  yield put(updateUserinfo({ isEmailSet, isPhoneSet }))

  if (cityTitle) yield fork(requestUpdateUser, cityTitle, authToken)

  return { userId: user.id }
}

function* vkPayAuthSaga() {
  yield call(VKWebAppInit)
  const user: VKUserInfo = yield call(VKWebAppGetUserInfo)
  yield put(setVkUser(user))

  const initialProps = urlPropsToObject<VKUserInitialParams>(window.location.search || '')
  return [ user, initialProps ]
}

function* apiConnectSessionSaga(body: VKUserInitialParams) {
  const [ response, error ]: [ VkConnectSession, Error ] = yield call(
    fetchJson,
    'vk_connect_sessions',
    { method: 'POST', body }
  )

  if (response) {
    const {
      auth_token: authToken,
      is_email_set: isEmailSet,
      is_phone_set: isPhoneSet
    } = response

    return [ { authToken, isEmailSet, isPhoneSet }, null ]
  }

  return [ {}, error ]
}

function* requestAdditionalInfoSaga(authToken: string, user_id: number) {
  try {
    yield put(setPayStatus('Скажите электронную почту и телефон и мы пришлем вам чек после оплаты'))
    const userInfo: VkUserPhoneNumber & VkUserEmail = yield call(vkGetAdditionalUserInfoSaga)

    yield put(setPayStatus('Бережно сохраняем ваши данные'))
    const [ updatedAuthToken, addInfoUserError ] = yield call(apiPushAdditionalUserInfoSaga, authToken, { user_id, ...userInfo })

    if (addInfoUserError) throw Error(`Can't push additional user info with ${JSON.stringify(userInfo)}: ${addInfoUserError.message}`)
    if (updatedAuthToken) {
      yield put(setAuthToken(updatedAuthToken))
      yield put(updateUserinfo({
        isEmailSet: Boolean(userInfo.email),
        isPhoneSet: Boolean(userInfo.phone_number)
      }))
    }
  } catch (err) {
    captureEvent({
      message: err.message,
      extra: err.stack,
    })
  }
}

function* requestVKPayParamsSaga(authToken: string, dealId: number, count: number) {
  yield put(setPayStatus('Оформляем заказ, считаем сумму оплаты, готовим ваш купон'))
  const [ orderResponse, orderError ]: [ OrderDeal, Error ] = yield call(apiCreateOrderSaga, authToken, dealId, count)
  if (orderError) throw Error(`Can't create an order deal with ${JSON.stringify({ dealId, count })}: ${orderError.message}`)

  yield put(setPayStatus('Включаем щедрость. Считаем сумму кешбэка.'))
  const [ vkPayParams, vkPayParamsError ]: [ VkPayPaymentParams, Error ] = yield call(apiRequestVkPayParamsSaga, authToken, orderResponse.id)
  if (vkPayParamsError) throw Error(`Can't create a vk pay request with ${JSON.stringify(orderResponse)}: ${vkPayParamsError.message}`)

  return vkPayParams
}

function* vkSendPayFormSaga(params: object) {
  yield put(setPayStatus('Ваш купон почти готов. Осталось только оплатить. Да не дрогнет рука ваша!'))
  try {
    return yield call(VKWebAppOpenPayForm, params)
  } catch (err) {
    if (err.error_data)
      captureEvent({
        message: err.error_data.error_reason.error_msg,
        extra: err.error_data.error_reason
      })
    else
      captureEvent({
        message: err.message,
        extra: err
      })
  }
}

function* vkGetAdditionalUserInfoSaga() {
  const { phone_number, sign: signed_phone_number }: VkUserPhoneNumber = yield call(VKWebAppGetPhoneNumber)
  if (phone_number) return { phone_number, signed_phone_number }

  const { email, sign: signed_email }: VkUserEmail = yield call(VKWebAppGetEmail)
  if (email) return { email, signed_email }

  throw Error('Can\'t proceed request without phone or email')
}

function* apiPushAdditionalUserInfoSaga(authToken: string, userAdditionalInfo: object) {
  const [ response, error ]: [ VkConnectSessionAddContacts, Error ] = yield call(
    fetchJson,
    'vk_connect_sessions/add_contacts',
    { authToken, method: 'POST', body: userAdditionalInfo }
  )

  if (response) return [ response.auth_token, null ]
  else [ null, error ]
}

function* apiCreateOrderSaga(authToken: string, deal_id: any, count = 1) {
  const [ response, error ]: [ CartOrderDealPostResponse, Error ] = yield call(
    fetchJson,
    'cart/order_deals',
    { authToken, method: 'POST', body: { deal_id, count } }
  )

  if (response && response.order_deal) {
    return [ response.order_deal, null ]
  } else {
    return [ null, error ]
  }
}

function* apiRequestVkPayParamsSaga(authToken: string, order_deal_id: number) {
  return yield call(
    fetchJson,
    'cart/vk_pay_payments',
    { authToken, method: 'POST', body: { order_deal_id } }
  )
}

function* apiCheckPaymentId(authToken: string, paymentId: number, interval: number, attempts: number) {
  const [ response, error ]: [ { payment: Payment }, Error ] = yield call(
    fetchJson, `cart/payments/${paymentId}`, { authToken }
  )

  if (attempts === 0 || error)
    return false

  switch (response && response.payment && response.payment.state) {
    case 'declined':
      return false
    case 'confirmed':
      return true
    default:
      yield delay(interval)
      return yield call(apiCheckPaymentId, authToken, paymentId, interval, --attempts)
  }
}
