import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { View, Panel } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { changeTabBar, changeActivePanel, setActiveDealContainer } from '~/actions'

import PanelHeaderBack from '~/components/shared/PanelHeaderBack'
import DealContainersCategoryList from './parts/DealContainersCategoryList'
import { DealContainer, Category } from '~/models/api'

interface OwnProps {
  id: string
}

interface StateProps {
  category: IState['categories'][any]
  activePanel: IState['ui']['activePanel']
  activeCategory: IState['activeData']['activeCategory']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
  changeActivePanel: typeof changeActivePanel
  setActiveDealContainer: typeof setActiveDealContainer
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ categories, activeData: { activeCategory }, ui: { activePanel } }: IState) =>
    ({ category: categories.find(({ slug }) => slug === activeCategory) as Category, activeCategory, activePanel }),
  { changeTabBar, changeActivePanel, setActiveDealContainer }
)

const List: SFC<Props> = ({ id, category, activeCategory, activePanel, changeTabBar, changeActivePanel, setActiveDealContainer }) =>
  <View id={id} activePanel={activePanel}>
    <Panel id='main'>
      <PanelHeaderBack
        goBack={() => {
          changeTabBar('categories')
        }} >
        { category && category.name }
      </PanelHeaderBack>
      <DealContainersCategoryList
        category={activeCategory as string}
        onChoose={({ route }: DealContainer) => {
          setActiveDealContainer(route)
          changeTabBar('item')
          changeActivePanel('main')
        }}
      />
    </Panel>
  </View>

export default withConnect(List)
