import { put, call, takeEvery } from 'redux-saga/effects'

import { fetchJson } from './fetch'
import { getDealContainer, setDealContainer, changeTabBar } from '~/actions'

function* fetchDealContainerSaga({ payload: route }) {
  const [ response, error ] = yield call(fetchJson, `deal_containers/${route}`)
  if (error) return yield put(changeTabBar('errorFetchDealContainer'))
  else yield put(setDealContainer(response.deal_container))
}

export default function* () {
  yield takeEvery(getDealContainer, fetchDealContainerSaga)
}
