import { all, fork, put, call, take, select } from 'redux-saga/effects'

import { fetchJson } from './fetch'
import { Coupon } from '../models/api'
import { selectAuthToken } from './selectors'
import { requestCoupons, setCoupons, requestCouponQr, setCouponQr, couponEntityAction } from '../actions'

function* fetchCouponsSaga() {
  while (true) {
    const { payload: scope } = yield take(requestCoupons)
    const authToken = yield select(selectAuthToken)

    const [ response ]: [ { coupons: Coupon[] } ] = yield call(fetchJson, `coupons?scope=${scope}`, { authToken })
    if (response) yield put(setCoupons(response.coupons))
  }
}

function* fetchCouponQrSaga() {
  while (true) {
    const { payload: id } = yield take(requestCouponQr)
    const authToken = yield select(selectAuthToken)

    const [ response ]: [ Blob ] = yield call(fetchJson, `coupons/${id}/barcode`, { authToken, type: 'blob' })
    const qr = URL.createObjectURL(response)
    if (response) yield put(setCouponQr([ id, qr ]))
  }
}

function* watchForCouponEntityActionSaga() {
  function saveFile(blob, fileName) {
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    document.body.appendChild(a)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  while (true) {
    const { payload: [ type, id ] } = yield take(couponEntityAction)
    const authToken = yield select(selectAuthToken)

    if (type === 'pdf') {
      const [ response ]: [ Blob ] = yield call(fetchJson, `coupons/${id}.pdf`, { authToken, type: 'blob' })
      saveFile(response, `coupon_${id}.pdf`)
    }

    if (type === 'img') {
      const [ response ]: [ Blob ] = yield call(fetchJson, `coupons/${id}.png`, { authToken, type: 'blob' })
      saveFile(response, `coupon_${id}.png`)
    }

    if (type === 'email') {
      yield call(fetchJson, `coupons/${id}/send_to_email`, { authToken })
    }

    if (type === 'sms') {
      yield call(fetchJson, `coupons/${id}/send_to_sms`, { authToken })
    }

    if (type === 'used') {
      yield call(fetchJson, `coupons/${id}/toggle_used?event=use`, { authToken })
    }

  }
}

export default function* () {
  yield all([
    fork(fetchCouponsSaga),
    fork(fetchCouponQrSaga),
    fork(watchForCouponEntityActionSaga)
  ])
}
