import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { Group, List } from '@vkontakte/vkui'

import { requestCouponQr } from '~/actions'
import { IState } from '~/reducers'

import CouponsListItem from './CouponsListItem'

interface DispatchProps {
  requestCouponQr: typeof requestCouponQr
}

interface OwnProps {
  ids: string[]
  entities: IState['coupons']
  onChoose: (a: string) => void
}

type Props = DispatchProps & OwnProps

const withConnect = connect<{}, DispatchProps, OwnProps>(null, { requestCouponQr })

const CouponsList: SFC<Props> = ({ entities, ids, requestCouponQr, onChoose }) =>
  <Group style={{ marginTop: '50px' }}>
    <List>
      { ids.map(id =>
        <CouponsListItem
          key={id}
          coupon={entities[id]}
          { ...{ requestCouponQr, onChoose } }
        />
      ) }
    </List>
  </Group>

export default withConnect(CouponsList)
