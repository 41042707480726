import { all, call, fork, take } from 'redux-saga/effects'

import dealContainerSaga from './dealContainer'
import dealContainersSaga from './dealContainers'
import promoBlocks from './promoBlocks'
import topicSets from './topicSets'
import vkPaySaga from './vkPay'
import couponsSaga from './coupons'
import categoriesSaga from './categories'
import citiesSaga, { fetchCitiesSaga } from './cities'
import location from './location'
import twoGis from './twoGis'

import { setCurrentCity } from '~/actions'

export default function* () {
  yield all([
    fork(dealContainerSaga),
    fork(twoGis),
    fork(couponsSaga),
  ])

  // All sagas below depends on cities
  yield call(fetchCitiesSaga)

  yield fork(citiesSaga)

  yield fork(vkPaySaga)

  yield take(setCurrentCity)

  yield all([
    fork(promoBlocks),
    fork(topicSets),
    fork(categoriesSaga),
    fork(dealContainersSaga),
    // Location's sagas requests some fetches, i.e. requestCouponQr
    fork(location),
  ])
}
