import React, { useState, useEffect, FC } from 'react'
import { List, Cell, InfoRow, FixedLayout, Tabs, TabsItem, Button, Div, Group, ScreenSpinner } from '@vkontakte/vkui'

import { DealContainer, DealContainerFull } from '~/models/api'
import TwoGisMapSpotsMap from '~/components/shared/TwoGisSpotsMap'

interface OwnProps {
  dealContainer: DealContainer | DealContainerFull
  onBuyClick: () => void
}

const getMonthStr = (m: number) =>
  [ 'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря' ][m]

const Main: FC<OwnProps> = ({ dealContainer, onBuyClick }) => {
  const [ activeTab, setActiveTab ] = useState('conditions')
  const isDCLoaded = Boolean(dealContainer && (dealContainer as DealContainerFull).conditions)
  const endDate = new Date((dealContainer && dealContainer.coupon_finish) || '')
  const formatedEndDate = `${endDate.getUTCDate()} ${getMonthStr(endDate.getUTCMonth())} ${endDate.getUTCFullYear()}`

  useEffect(() => {
    try {
      window.scroll({
        top: 480, 
        left: 0, 
        behavior: 'smooth'
      })
    } catch(err) {
      window.scrollTo(0, 480)
    }
  }, [activeTab])

  return !(dealContainer && dealContainer.images)
    ? <ScreenSpinner size='large' style={{ paddingBottom: 40 }} />
    : <div style={{ paddingTop: 45 }}>
        <img
          src={dealContainer.images.full}
          alt={(dealContainer as DealContainerFull).seo_title}
          style={{ width: '100%', minHeight: 184 }} />
        <Group>
          <List>
            <Cell>
              <InfoRow title="Скидка">{dealContainer.discount}%</InfoRow>
            </Cell>
            <Cell>
              <InfoRow title="Стоимость купона">{dealContainer.cost} {dealContainer.currency}</InfoRow>
            </Cell>
            <Cell>
              <InfoRow title="Купонов куплено">{dealContainer.sold_count}</InfoRow>
            </Cell>
          </List>
        </Group>
        <Div>
          <Button
            disabled={!isDCLoaded}
            size="xl"
            level="commerce"
            onClick={onBuyClick}>
            Купить купон
          </Button>
        </Div>
        <Group style={{ margin: 0 }}>
          <FixedLayout vertical="top">
            <Tabs theme="header" type="buttons" style={{ justifyContent: 'space-around' }}>
              <TabsItem
                onClick={() => setActiveTab('conditions')}
                selected={activeTab === 'conditions'} >
                Условия
              </TabsItem>
              <TabsItem
                onClick={() => setActiveTab('addresses')}
                selected={activeTab === 'addresses'} >
                Адреса
              </TabsItem>
            </Tabs>
          </FixedLayout>
          { activeTab === 'conditions'
              ? <Div style={{ paddingBottom: 60 }}>
                  <strong>Купон действует до { formatedEndDate }</strong>
                  <div dangerouslySetInnerHTML={{ __html: dealContainer && (dealContainer as DealContainerFull).conditions }} />
                </Div>
              : <TwoGisMapSpotsMap spots={dealContainer.spots} />
          }
        </Group>
      </div>
}

export default Main