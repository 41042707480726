import React, { SFC, useState, ReactElement, useEffect } from 'react'
import { Gallery, Button } from '@vkontakte/vkui'

import { getTourShown, setTourShown } from '~/services/cookie'

const Slide = ({ pic, next, btnNextLabel, children }: { pic: string, btnNextLabel?: string, children: ReactElement, next: () => void }) =>
  <div>
    <h1 style={{
      fontSize: '1.1rem',
      fontWeight: 'normal',
      height: '20vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      margin: '10vh 0 0 0',
      lineHeight: '1.8rem',
    }}>
      { children }
    </h1>
    <div style={{
      background: `url("${pic}") center center no-repeat`,
      backgroundSize: 'contain',
      width: '100vw',
      height: '60vh',
    }} />
    <div style={{
      display: 'flex',
      height: '10vh',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Button onClick={next} level="outline" size="l">{ btnNextLabel || 'Далее' }</Button>
    </div>
  </div>

const WelcomeTour: SFC = () => {
  let [ slide, setSlide ] = useState(0)
  let [ show, setShow ] = useState(true)

  useEffect(() => {
    let isTourShown = getTourShown()
    isTourShown
      ? setShow(false)
      : setTourShown(true)
  }, [])

  return !show ? <div/> : (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9,
      width: '100vw',
      height: '100vh',
      background: 'var(--background_page)'
    }}>
      <Gallery
        slideWidth="100%"
        align="center"
        style={{ height: '100vh' }}
        slideIndex={slide}
        onChange={index => setSlide(index)}
      >
        <Slide
          pic='/assets/tour/0.png'
          next={() => setSlide(slide + 1)} >
          <>
            Купоны на скидки до 90%<br/>
            на рестораны, красоту,<br/>
            развлечения
          </>
        </Slide>
        <Slide
          pic='/assets/tour/1.png'
          next={() => setSlide(slide + 1)} >
          <>
            Для получения скидки достаточно<br/>
            предъявить купон с экрана телефона<br/>
            или в распечатанном виде
          </>
        </Slide>
        <Slide
          pic='/assets/tour/2.png'
          next={() => setSlide(slide + 1)} >
          <>
            Покупка осуществляется<br/>
            оплатой через VK Pay
          </>
        </Slide>
        <Slide
          pic='/assets/tour/3.png'
          next={() => setShow(false)}
          btnNextLabel='Перейти к покупкам'>
          <>
            После покупки 20% от стоимости<br/>
            купона вернётся кешбэком<br/>
            на счёт VK Pay
          </>
        </Slide>
      </Gallery>
    </div>
  )
}

export default WelcomeTour
