export const apiPath = (() => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://api.vkservice.kupikupon.ru/api/v2/'
    case 'staging':
      return 'https://api.vkservice.dev.kupikupon.ru/api/v2/'
    default:
      return 'http://localhost:3000/'
  }
})()
