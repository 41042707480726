import React, { SFC } from 'react'
import { View, Panel, PanelHeader, Div, FormStatus } from '@vkontakte/vkui'

import CitiesSelect from '~/components/shared/CitiesSelect'

interface Props {
  id: string
  statusText: string
}

const ErrorCityResolve: SFC<Props> = ({ id, statusText }) =>
  <View id={id} activePanel="main">
    <Panel id="main">
      <PanelHeader>Дополните профиль</PanelHeader>
      <Div>
        <FormStatus title={statusText} state="error">
          Но скоро обязательно будем. Пока выберите город из списка ниже, если вдруг планируете посетить и сэкономить на ресторанах, салонах красоты и развлечениях
        </FormStatus>
      </Div>
      <CitiesSelect />
    </Panel>
  </View>

export default ErrorCityResolve
