import { createReducer } from 'redux-act'
import { setPayStatus, setPaymentStatus } from '../actions'
import { VkTransactionFull } from '../models/vk'


const defaultState = {
  status: null as string | null,
  result: null as VkTransactionFull | null
}
const reducer = createReducer({}, defaultState)

reducer.on(setPayStatus, (state, payload) =>
  ({ ...state, status: payload }))
reducer.on(setPaymentStatus, (state, payload) =>
  ({ ...state, result: payload }))

export default reducer
export type State = typeof defaultState
