import React, { FC } from 'react'
import { connect } from 'react-redux'
import { View, Panel, Div, Button, FormStatus } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { changeTabBar } from '~/actions'

import PanelHeader from '~/components/shared/PanelHeader'

interface OwnProps {
  id: string
  title: string
  text: string
}

interface StateProps {
  prevTabBar: IState['ui']['prevTabBar']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ ui: { prevTabBar } }: IState) => ({ prevTabBar }),
  { changeTabBar }
)

const ErrorFetch: FC<Props> = ({ id, title, text, changeTabBar, prevTabBar }) =>
  <View id={id} activePanel="main">
    <Panel id="main">
      <PanelHeader>Проблема загрузки</PanelHeader>
      <Div>
        <FormStatus title={title} state="error">
          {text}
        </FormStatus>
        <Button onClick={() => changeTabBar(prevTabBar)}>Назад</Button>
      </Div>
    </Panel>
  </View>

export default withConnect(ErrorFetch)
