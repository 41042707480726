import React, { useEffect, useState, SFC } from 'react'
import { connect } from 'react-redux'
import { Div, View, Panel, PanelHeader, PopoutWrapper, Spinner, Group, List, Cell } from '@vkontakte/vkui'

import Icon24Download from '@vkontakte/icons/dist/24/download'
import Icon24Send from '@vkontakte/icons/dist/24/send'
import Icon24Comment from '@vkontakte/icons/dist/24/comment'
import Icon24Attachments from '@vkontakte/icons/dist/24/attachments'
import Icon24Done from '@vkontakte/icons/dist/24/done'

import { IState } from '~/reducers'
import { requestCoupons, couponEntityAction, setActiveCoupon, changeTabBar } from '~/actions'
import { CouponsScope } from '~/models/api'

import CouponsList from './parts/CouponsList'
import CouponsTabs from './parts/CouponsTabs'

interface OwnProps {
  id: string
}

interface StateProps {
  coupons: IState['coupons']
  activeCoupon: IState['activeData']['activeCoupon']
}

interface DispatchProps {
  requestCoupons: typeof requestCoupons
  couponEntityAction: typeof couponEntityAction
  setActiveCoupon: typeof setActiveCoupon
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps & OwnProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ coupons, activeData: { activeCoupon } }: IState) => ({ coupons, activeCoupon }),
  { requestCoupons, couponEntityAction, setActiveCoupon, changeTabBar }
)

const Coupons: SFC<Props> = ({ id, coupons, activeCoupon, setActiveCoupon, requestCoupons, couponEntityAction, changeTabBar }) => {
  const [ selectedTab, setActiveTab ] = useState('active' as CouponsScope)
  const ids = Object.keys(coupons).filter(k => coupons[k].scope === selectedTab)
  const setActive = (id: string | null) => {
    setActiveCoupon(id)
    changeTabBar('coupons')
  }

  useEffect(() => (requestCoupons(selectedTab), void 0), [ selectedTab ])

  return <>
    <View id={ id } activePanel={selectedTab} popout={
      activeCoupon &&
        <PopoutWrapper v="center" h="center" onClick={() => setActive(null)}>
          <Div style={{ background: '#fff', display: 'flex', flexFlow: 'column nowrap' }}>
            { coupons[activeCoupon] && coupons[activeCoupon].qr
              ? <>
                <div style={{fontSize: '.9rem'}}>{coupons[activeCoupon].title}</div>
                <img src={coupons[activeCoupon].qr} style={{ width: '70%', alignSelf: 'center' }} />
                <Group>
                  <List>
                    <Cell
                      before={<Icon24Download />}
                      onClick={() => couponEntityAction([ 'pdf', activeCoupon ])}>
                      Скачать PDF
                    </Cell>
                    {/* <Cell
                      before={<Icon24Comment />}
                      onClick={() => couponEntityAction([ 'sms', activeCoupon ])}>
                      Отправить в СМС
                    </Cell>
                    <Cell
                      before={<Icon24Send />}
                      onClick={() => couponEntityAction([ 'email', activeCoupon ])}>
                      Отправить на Email
                    </Cell>
                    <Cell
                      before={<Icon24Attachments />}
                      onClick={() => couponEntityAction([ 'img', activeCoupon ])}>
                      Сохранить как картинку
                    </Cell>
                    <Cell
                      before={<Icon24Done />}
                      onClick={() => couponEntityAction([ 'used', activeCoupon ])}>
                      Купон использован
                    </Cell> */}
                  </List>
                </Group>
              </>
              : <Spinner />
            }
          </Div>
        </PopoutWrapper>
    }>
      <Panel id='active'>
        <PanelHeader noShadow>Мои активные купоны</PanelHeader>
        <CouponsList entities={coupons} ids={ids} onChoose={setActive} />
        <CouponsTabs { ...{ selectedTab, setActiveTab } } />
      </Panel>
      <Panel id='used'>
        <PanelHeader>Мои использованные купоны</PanelHeader>
        <CouponsList entities={coupons} ids={ids} onChoose={setActive} />
        <CouponsTabs { ...{ selectedTab, setActiveTab } } />
      </Panel>
      <Panel id='inactive'>
        <PanelHeader>Мои просроченные купоны</PanelHeader>
        <CouponsList entities={coupons} ids={ids} onChoose={setActive} />
        <CouponsTabs { ...{ selectedTab, setActiveTab } } />
      </Panel>
    </View>
  </>
}

export default withConnect(Coupons)
