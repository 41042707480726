import React, { ReactNode } from 'react'
import { PanelHeader, HeaderButton, platform, IOS } from '@vkontakte/vkui'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon24MarketOutline from '@vkontakte/icons/dist/24/market_outline'

const osname = platform()

interface Props {
  goNext?: () => void
  goBack?: () => void
  children: ReactNode
}

export default ({ children, goBack, goNext }: Props) =>
  <PanelHeader
    left={!goBack ? <div/> :
      <HeaderButton onClick={goBack}>
        {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
      </HeaderButton>
    }
    addon={!goNext ? <div/> :
      <HeaderButton onClick={goNext}>
        <Icon24MarketOutline />
      </HeaderButton>
    }
    right={!goNext ? <div/> :
      <HeaderButton onClick={goNext}>
        <Icon24MarketOutline />
      </HeaderButton>
    }>
    {children}
  </PanelHeader>
