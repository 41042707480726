import React, { useEffect, SFC } from 'react'

import { DealContainer } from '~/models/api'

export interface State {
  dcIds: number[]
  address: string
  metro: string
  distance: number
}

declare global {
  interface Window {
    DG: any
  }
}

interface OwnProps {
  spots: DealContainer['spots']
  height?: number | string
}

const TwoGisMapContainer: SFC<OwnProps> = ({ spots, height }) => {
  // TODO: Move to sagas
  useEffect(() => {
    let mapInstance
    setTimeout(() => {
      const { DG } = window
      // TODO: how to scroll inside webview? DIV's>
      // window.scrollTo(9999, document.body.scrollHeight)
      let map: any

      DG
        .then(() =>
          DG.plugin('https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js')
        )
        .then(() => {
          map = window.DG.map('map', {
            'center': [ 55.75222, 37.61556 ],
            'zoom': 13
          })
          let markers = DG.markerClusterGroup()

          map.locate({ setView: true, watch: true })
            .on('locationfound', function(e: { latitude: number, longitude: number }) {
                DG.marker([ e.latitude, e.longitude ]).addTo(markers)
            })
            .on('locationerror', function(e) {
                DG.popup()
                  .setLatLng(map.getCenter())
                  .setContent('Доступ к определению местоположения отключён')
                  .openOn(map)
            })

          spots.map(spot => {
            let latlng = [ spot.latitude, spot.longitude ]
            let popup = DG.popup({
              mixWidth: 500,
              maxWidth: 500
            })
              .setLatLng(latlng)
              .setHeaderContent(`${spot.street}, ${spot.house}`)
              .setContent(`
                <p>${spot.hours}</p>
                <p>
                  <ul style="margin:0;padding:0;">
                    ${spot.phone && spot.phone.split(' ').map(p => `<li>${p}</li>`)}
                  </ul>
                </p>
              `)

            DG.marker(latlng)
              .on('click', () => {
                map.openPopup(popup)
                map.setView(latlng)
              })
              .addTo(markers)
          })

          map.addLayer(markers)
          map.fitBounds(markers.getBounds())
          mapInstance = map
        })
    }, 20)
    return () => mapInstance && mapInstance.remove()
  }, [ spots ])

  return <div id='map' style={{ width: '100vw', height: height || '69vh' }} />
}

export default TwoGisMapContainer