import React, { useEffect, useState, SFC } from 'react'
import { connect } from 'react-redux'
import { View, Panel, PanelHeader } from '@vkontakte/vkui'
import { useScrollToTop } from '~/hooks/useScrollToTop'

import { IState } from '~/reducers'
import { changeActivePanel, getDealContainer, requestBuyDeal } from '~/actions'
import { DealContainer, DealContainerFull, Deal } from '~/models/api'
import { trackAddToCart } from '~/services/gtm'

import Main from './panels/Main'
import ChooseDeals from './panels/ChooseDeals'
import PanelHeaderCustom from '~/components/shared/PanelHeader'
import ChooseOptions from './panels/ChooseOptions'
import AwaitProgress from './panels/AwaitProgress'
import BuyResult from './panels/BuyResult'

interface OwnProps {
  id: string
}

interface StateProps {
  activePanel: IState['ui']['activePanel']
  prevPanel: IState['ui']['prevPanel']
  activeDealContainer: IState['activeData']['activeDealContainer']
  dealContainer: DealContainer | DealContainerFull
}

interface DispatchProps {
  changeActivePanel: typeof changeActivePanel
  getDealContainer: typeof getDealContainer
  requestBuyDeal: typeof requestBuyDeal
}

type Props = OwnProps & StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps, OwnProps>(
  ({ ui: { activePanel, prevPanel }, activeData: { activeDealContainer }, dealContainers }: IState) =>
    ({
      activePanel,
      prevPanel,
      activeDealContainer,
      dealContainer: dealContainers.entities[activeDealContainer as string]
    }),
  {
    changeActivePanel,
    getDealContainer,
    requestBuyDeal
  }
)

const Item: SFC<Props> = ({
  id,
  activePanel,
  prevPanel,
  dealContainer,
  activeDealContainer,
  changeActivePanel,
  getDealContainer,
  requestBuyDeal
}) => {
  const [ activeDeal, setActiveDeal ] = useState(null as null | Deal)
  const goBack = () => changeActivePanel(prevPanel)

  useScrollToTop()

  useEffect(() => {
    getDealContainer(activeDealContainer as string)
    // TODO: how to scroll inside webview? DIV's>
    // window.scrollTo({ top: 0 })
  }, [ activeDealContainer ])

  return (
    <View id={id} activePanel={activePanel}>
      <Panel id='main'>
        <PanelHeaderCustom>Акция</PanelHeaderCustom>
        <Main
          dealContainer={dealContainer}
          onBuyClick={() => changeActivePanel('chooseDeals')} />
      </Panel>

      <Panel id='chooseDeals'>
        <PanelHeaderCustom goBack={goBack}>Выберите купон</PanelHeaderCustom>
        <ChooseDeals
          dealContainer={dealContainer as DealContainerFull}
          onSelectDeal={(deal) => {
            setActiveDeal(deal)
            changeActivePanel('chooseOptions')
          }} />
      </Panel>

      <Panel id='chooseOptions'>
        <PanelHeaderCustom goBack={goBack}>Покупка</PanelHeaderCustom>
        <ChooseOptions
          activeDeal={activeDeal as Deal}
          onRequestBuy={({ count }) => {
            trackAddToCart((activeDeal as Deal).title, count)
            requestBuyDeal({ id: (activeDeal as Deal).id, num: count })
            changeActivePanel('awaitProgress')
          }}
        />
      </Panel>

      <Panel id='awaitProgress'>
        <PanelHeaderCustom>Процесс покупки</PanelHeaderCustom>
        <AwaitProgress onComplete={() => changeActivePanel('buyResult')}/>
      </Panel>

      <Panel id='buyResult'>
        <PanelHeader>Покупка совершена</PanelHeader>
        <BuyResult />
      </Panel>
    </View>
  )
}

export default withConnect(Item)
