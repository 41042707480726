import { call, race, delay } from 'redux-saga/effects'
import { captureEvent } from 'sentry-cordova'
import { apiPath } from '~/services/api'

type FetchOptions = {
  body?: any
  method?: string
  authToken?: string
  type?: 'json' | 'blob'
}

const makeFetchOptions = ({ body, method, authToken }: FetchOptions = {}): RequestInit => ({
  method: method || 'GET',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': authToken ? `Basic ${authToken}` : null,
  } as any,
  body: body ? JSON.stringify(body) : null,
})

export function* fetchJson(path: string, options?: FetchOptions): IterableIterator<[ unknown?, Error? ]> {
  try {
    const [ res, timeout ] = yield race([
      call(window.fetch, `${apiPath}${path}`, makeFetchOptions(options)),
      delay(10000)
    ]) as any

    if (timeout) throw Error(`Request timeout for "${path}"`)
    const response = yield res[(options && options.type) || 'json']()

    if (response.errors && response.errors.length > 0) {
      const [ firstError, ...otherErrors ] = response.errors as string[]
      captureEvent({ message: `Server error: ${firstError}`, extra: otherErrors })
      throw Error(firstError)
    }

    if (!res.ok) throw Error(res.statusText)

    return [ response, null ]
  } catch (error) {
    console.error(error)
    return [ null, error ]
  }
}
