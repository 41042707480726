import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { Cell, List, Spinner, Search } from '@vkontakte/vkui'

import { setCurrentCity, changeTabBar } from '~/actions'
import { IState } from '~/reducers'

interface StateProps {
  cities: IState['cities']['entities']
}

interface DispatchProps {
  changeTabBar: typeof changeTabBar
  setCurrentCity: typeof setCurrentCity
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ cities: { entities } }: IState) => ({ cities: entities }),
  { changeTabBar, setCurrentCity }
)

const CitiesSelect: FC<Props> = ({ cities, changeTabBar, setCurrentCity }) => {
  let [ search, setSearch ] = useState('')

  return cities.length <= 0
    ? <Spinner size='large' />
    : <>
      <Search value={search} onChange={(val: string) => setSearch(val)}/>
      <List>
        { cities.filter(c => c.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map(c =>
          <Cell
            expandable
            key={c.id}
            onClick={() => {
              setCurrentCity(c.name)
              changeTabBar('home')
            }}>
            {c.name}
          </Cell>
        ) }
      </List>
     </>
}

export default withConnect(CitiesSelect)
