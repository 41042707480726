import React, { SFC } from 'react'
import { List, Cell, Avatar, Div, Button, Spinner } from '@vkontakte/vkui'
import { useScrollToTop } from '~/hooks/useScrollToTop'

import { DealContainer } from '~/models/api'

interface OwnProps {
  entities: DealContainer[]
  onChoose: (dc: DealContainer) => void
  onMore: () => void
  isLoading: boolean
  moreDisabled: boolean
  disableSpinner?: boolean
}

type Props = OwnProps

const DealContainersList: SFC<Props> = ({ entities, onChoose, isLoading, onMore, moreDisabled, disableSpinner }) => {
  useScrollToTop()

  return isLoading
    ? <Spinner size='large' style={{ paddingBottom: 40 }} />
    : !entities
      ? <div/>
      : !disableSpinner && !isLoading && entities.length === 0
        ? <Div style={{ paddingTop: 24, paddingLeft: 20, paddingRight: 20, textAlign: 'center' }}>На данный момент в вашем городе в текущей категории нет активных акций</Div>
        : <>
        <List>
          { entities.map(dc => (
            <Cell
              expandable
              multiline
              onClick={() => onChoose(dc)}
              data-route={dc.route}
              key={dc.id}
              description={ `скидка ${dc.discount}% от ${dc.cost} ${dc.currency}` }
              before={
                <Avatar type="image" src={dc.images.preview} style={{ objectFit: 'cover' }} />
              } >
              {dc.title}
            </Cell>
          )) }
        </List>
        { moreDisabled ||
          <Div style={{ paddingBottom: 60 }}>
            {isLoading
              ? <Spinner size='large' />
              : <Button
                  size="xl"
                  level="2"
                  onClick={onMore}>
                  Показать больше
                </Button>
            }
          </Div>
        }
      </>
}

export default DealContainersList
