import React, { useEffect, SFC } from 'react'
import { connect } from 'react-redux'
import { Gallery, Spinner, Div, Group } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { requestTopicSets, setActiveTopicSet, changeTabBar } from '~/actions'

interface StateProps {
  topicSets: IState['topicSets']
  currentCity: IState['cities']['current']
  topicSetsIsEmpty: IState['ui']['topicSetsIsEmpty']
}

interface DispatchProps {
  setActiveTopicSet: typeof setActiveTopicSet
  requestTopicSets: typeof requestTopicSets
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ topicSets, cities: { current: currentCity }, ui: { topicSetsIsEmpty } }: IState) =>
    ({ topicSets, currentCity, topicSetsIsEmpty }),
  { requestTopicSets, setActiveTopicSet, changeTabBar }
)

const TopicSets: SFC<Props> = ({ requestTopicSets, topicSets, setActiveTopicSet, topicSetsIsEmpty, changeTabBar, currentCity }) => {
  useEffect(() => { requestTopicSets() }, [ currentCity ])
  const ids = Object.keys(topicSets)

  return topicSetsIsEmpty
    ? <div />
    : ids.length <= 0
      ? <Spinner size='large' style={{ paddingBottom: 40 }} />
      : <Group title="Подборки акций">
        <Gallery
          slideWidth={150}
          align="right"
          style={{ height: 150 }} >
          { ids.map(id => (
            <Div
              key={topicSets[id]}
              onClick={() => {
                setActiveTopicSet(topicSets[id].slug)
                changeTabBar('topic')
              }}
              style={{
                height: 'inherit',
                padding: '0 0 .5rem .5rem',
                boxSizing: 'border-box',
              }}>
              <div style={{
                background: `url("${topicSets[id].image_url}")`,
                backgroundSize: 'cover',
                width: '100%',
                height: 'inherit',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
                {topicSets[id].title}
              </div>
            </Div>
          )) }
        </Gallery>
      </Group>
}

export default withConnect(TopicSets)