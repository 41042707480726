import { createAction } from 'redux-act'

import { VKUserInfo, VkTransactionFull } from '../models/vk'
import { DealContainer, TopicSet, PromoBlock, Coupon, Category, TopicSetFull, City, DealContainerSpot, DealContainerFull } from '../models/api'

/* DealContainers */
export const addDealContainers = createAction<DealContainer[]>('add deal containers')
export const clearDealContainers = createAction<void>('clear deal containers')
export const getDealContainer = createAction<string>('get deal container by route')
export const getDealContainers = createAction<{ category: string, page: number }>('get deal containers')
export const requestSearchDealContainers = createAction<string>('request search deal containers')
export const setDealContainer = createAction<DealContainer>('set deal container')
export const setDealContainers = createAction<DealContainer[]>('set deal containers')
export const setDealContainersLoading = createAction<boolean>('set deal containers loading')
export const setSearchResultsDealContainers = createAction<DealContainer[]>('set search results deal containers')

/* User */
export const setVkUser = createAction<VKUserInfo>('set vk user')
export const setAuthToken = createAction<string>('set auth token')
export const requestUserContacts = createAction('request user contects')
export const updateUserinfo = createAction<{ isEmailSet: boolean, isPhoneSet: boolean }>('update user info')

/* Deals and Payments */
export const requestBuyDeal = createAction<{ id: number, num: number }>('request buy deal')
export const setPayStatus = createAction<string>('set pay status')
export const setPaymentStatus = createAction<VkTransactionFull>('set payment status')
export const resetPayStatus = createAction('reset pay status')

/* TopicSets */
export const requestTopicSets = createAction<void>('request topic sets')
export const requestTopicSet = createAction<string>('request topic set by slug')
export const setTopicSets = createAction<TopicSet[]>('set topic sets')
export const updateTopicSet = createAction<TopicSetFull>('update topic set')
export const setTopicSetsEmpty = createAction<void>('set topic sets empty')

/* PromoBlocks */
export const requestPromoBlocks = createAction<void>('request promo blocks')
export const setPromoBlocks = createAction<PromoBlock[]>('set promo blocks')

/* Coupons */
export const requestCoupons = createAction<'active' | 'used' | 'inactive'>('request coupons')
export const setCoupons = createAction<Coupon[]>('set coupons')
export const requestCouponQr = createAction<string>('request coupon qr')
export const setCouponQr = createAction<[ string, string ]>('set coupon qr')
export const couponEntityAction = createAction<[ 'img' | 'pdf' | 'sms' | 'email' | 'used', string ]>('coupon entity action')

/* Categories */
export const requestCategories = createAction<void>('request categories')
export const setCategories = createAction<Category[]>('set categories')

/* Cities */
export const setCities = createAction<City[]>('set cities')
export const setCurrentCity = createAction<string>('set current city')

/* Temp? */
export const setActiveDealContainer = createAction<string>('set active deal container route')
export const setActiveCategory = createAction<string>('set active category slug')
export const setActiveTopicSet = createAction<string>('set active topic set slug')
export const setActiveCoupon = createAction<string | null>('set active coupon id')

export const initGlobalMap = createAction<void>('init global map')
export const setMapLoadingState = createAction<boolean>('set map loading state')
export const setActiveSpotsForMap = createAction<{ spot: DealContainerSpot, dealContainer: DealContainerFull }[]>('set active spots for map')

export * from './ui'
