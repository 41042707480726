import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { StoreContext } from 'redux-react-hook'
import { captureEvent } from 'sentry-cordova'
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper'

import { initTagManager } from '~/services/gtm'
import store from '~/store'
import App from '~/components/App'
import CriticalError from '~/components/CriticalError'

if (process.env.NODE_ENV === 'production')
  initTagManager()

if (process.env.NODE_ENV === 'development')
  require('./hmr')

const root = document.getElementById('root')

mVKMiniAppsScrollHelper(root)

export const renderError = (e: Error) =>
  render(<CriticalError error={e instanceof Error ? e : Error(e)} />, root)

export const renderApp = () => {
  try {
    render(
      <Provider store={ store }>
        <StoreContext.Provider value={ store }>
          <App />
        </StoreContext.Provider>
      </Provider>,
      root
    )
  } catch (e) {
    renderError(e)
    captureEvent({ message: e.message, extra: e })
  }
}

renderApp()
