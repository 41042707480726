import React, { SetStateAction } from 'react'
import { FixedLayout, Tabs, TabsItem } from '@vkontakte/vkui'
import { CouponsScope } from '~/models/api'

type Props = {
  selectedTab: CouponsScope
  setActiveTab: (a: SetStateAction<CouponsScope>) => void
}

const Small = ({ children }: any) =>
  <span style={{ fontSize: '.8rem' }}>{children}</span>

const CouponsTabs = ({ selectedTab, setActiveTab }: Props) =>
  <FixedLayout vertical='top'>
    <Tabs>
      <TabsItem
        selected={selectedTab === 'active'}
        onClick={() => setActiveTab('active')}>
        <Small>Активные</Small>
      </TabsItem>
      <TabsItem
        selected={selectedTab === 'used'}
        onClick={() => setActiveTab('used')}>
        <Small>Использованные</Small>
      </TabsItem>
      <TabsItem
        selected={selectedTab === 'inactive'}
        onClick={() => setActiveTab('inactive')}>
        <Small>Просроченные</Small>
      </TabsItem>
    </Tabs>
  </FixedLayout>

export default CouponsTabs
