import { createReducer } from 'redux-act'

import { setDealContainers, setDealContainer, addDealContainers, setSearchResultsDealContainers, clearDealContainers, setDealContainersLoading } from '../actions'
import { DealContainer } from '../models/api'

const defaultState = {
  entities: {} as { [route: string]: DealContainer },
  ids: [] as string[],
  search: [] as string[],
  loading: false,
}

const dealContainers = createReducer({}, defaultState)

dealContainers.on(setDealContainers, (state, payload) => ({
  ...state,
  ids: payload.map(({ route }) => route),
  entities: { ...payload.reduce((a, b) => ({ ...a, [b.route]: b }), {}) }
}))

dealContainers.on(addDealContainers, (state, payload) => ({
  ...state,
  ids: [ ...state.ids, ...payload.map(({ route }) => route) ],
  entities: { ...state.entities, ...payload.reduce((a, b) => ({ ...a, [b.route]: b }), {}) }
}))

dealContainers.on(setDealContainer, (state, payload) => ({
  ...state,
  entities: { ...state.entities, [payload.route]: { ...state[payload.route], ...payload } }
}))

dealContainers.on(setSearchResultsDealContainers, (state, payload) => ({
  ...state,
  search: payload.map(({ route }) => route)
}))

dealContainers.on(clearDealContainers, (state) => ({ ...state, search: [] }))

dealContainers.on(setDealContainersLoading, (state, payload) => ({ ...state, loading: payload }))

export default dealContainers
export type State = typeof defaultState
