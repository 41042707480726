import React, { useEffect, SFC } from 'react'
import { connect } from 'react-redux'
import { Gallery, Spinner, Div } from '@vkontakte/vkui'

import { IState } from '~/reducers'
import { requestPromoBlocks, getDealContainer, setActiveDealContainer, changeTabBar } from '~/actions'
import { getRoute } from '~/helpers/promoBlocksHelpers'

interface StateProps {
  promoBlocks: IState['promoBlocks']
  currentCity: IState['cities']['current']
}

interface DispatchProps {
  requestPromoBlocks: typeof requestPromoBlocks
  getDealContainer: typeof getDealContainer
  setActiveDealContainer: typeof setActiveDealContainer
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ promoBlocks, cities: { current: currentCity } }: IState) =>
    ({ promoBlocks: promoBlocks.slice(1), currentCity }),
  { requestPromoBlocks, getDealContainer, setActiveDealContainer, changeTabBar }
)

const HotDeals: SFC<Props> = ({ changeTabBar, requestPromoBlocks, getDealContainer, promoBlocks, setActiveDealContainer, currentCity }: Props) => {
  useEffect(() => { requestPromoBlocks() }, [ currentCity ])

  return promoBlocks.length <= 0
    ? <Spinner size='large' style={{ paddingBottom: 40 }} />
    : <Gallery
        slideWidth='60%'
        align="right"
        style={{ height: 180 }} >
        { promoBlocks.map(promoBlock => (
          <Div
            onClick={() => {
              const route = getRoute(promoBlock)
              getDealContainer(route)
              setActiveDealContainer(route)
              changeTabBar('item')
            }}
            key={promoBlock.id}
            style={{
              height: 'inherit',
              padding: 0,
              boxSizing: 'border-box',
            }}>
            <div style={{
              margin: '.5rem',
              border: '1px solid #DFDFDF',
              height: 160
            }} >
              <div style={{
                background: `url(${promoBlock.image_url})`,
                height: 100,
                backgroundSize: 'cover',
              }} />
              <div style={{
                width: '100%',
                padding: '.5rem',
                boxSizing: 'border-box',
                height: 60,
                display: 'flex',
                alignItems: 'center',
              }}>
                {promoBlock.tiny_title}
              </div>
            </div>
          </Div>
        )) }
      </Gallery>
}

export default withConnect(HotDeals)
