import { createReducer } from 'redux-act'
import { setTopicSets, updateTopicSet } from '~/actions'
import { TopicSet } from '~/models/api'

const defaultState = {} as { [slug: string]: TopicSet }
const reducer = createReducer({}, defaultState)

reducer.on(setTopicSets, (_, payload) =>
  payload.reduce((a, b) => ({ ...a, [b.slug]: b }), {}))

reducer.on(updateTopicSet, (state, payload) =>
  ({ ...state, [payload.slug]: payload }))

export default reducer
export type State = typeof defaultState
