import React, { Component } from 'react'
import { captureException } from 'sentry-cordova'
import '@vkontakte/vkui/dist/vkui.css'

import SeparatedPages from './SeparatedPages'
import Epic from './Epic'
import WelcomeTour from './WelcomeTour'

export default class App extends Component {
	componentDidCatch(error: Error, info: any) {
		console.log({ error, info })
		captureException(error)
	}

	getRawStyles() {
		return `
			.View__panel {
				pointer-events:auto!important;
			}
		`
	}

	render() {
		return (
			<>
				<style dangerouslySetInnerHTML={{ __html: this.getRawStyles() }}></style>
				<SeparatedPages alternate={<Epic />} />
				<WelcomeTour />
			</>
		)
	}
}
