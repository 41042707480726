import { createReducer } from 'redux-act'
import { setActiveDealContainer, setActiveCategory, setActiveTopicSet, setActiveCoupon } from '~/actions'

const defaultState = {
  activeDealContainer: null as string | null,
  // prevDealContainer: null as string | null,
  activeCategory: null as string | null,
  activeTopicSet: null as string | null,
  activeCoupon: null as string | null,
}
const reducer = createReducer({}, defaultState)

reducer.on(setActiveDealContainer, (state, activeDealContainer) =>
  ({ ...state, activeDealContainer}))

reducer.on(setActiveCategory, (state, activeCategory) =>
  ({ ...state, activeCategory }))

reducer.on(setActiveTopicSet, (state, activeTopicSet) =>
  ({ ...state, activeTopicSet }))

reducer.on(setActiveCoupon, (state, activeCoupon) =>
  ({ ...state, activeCoupon }))

export default reducer
export type State = typeof defaultState
