import React, { SFC } from 'react'
import { connect } from 'react-redux'
import { Spinner } from '@vkontakte/vkui'

import { getDealContainer, setActiveDealContainer, changeTabBar } from '~/actions'
import { IState } from '~/reducers'
import { getRoute } from '~/helpers/promoBlocksHelpers'

interface StateProps {
  promoBlock: IState['promoBlocks'][any]
}

interface DispatchProps {
  setActiveDealContainer: typeof setActiveDealContainer
  getDealContainer: typeof getDealContainer
  changeTabBar: typeof changeTabBar
}

type Props = StateProps & DispatchProps

const withConnect = connect<StateProps, DispatchProps>(
  ({ promoBlocks }: IState) => ({ promoBlock: promoBlocks[0] }),
  { getDealContainer, setActiveDealContainer, changeTabBar }
)

const MainBanner: SFC<Props> = ({ promoBlock, setActiveDealContainer, getDealContainer, changeTabBar }) =>
  !promoBlock
    ? <Spinner size='large' style={{ padding: '4rem 0' }} />
    : <div
        className='mainBanner'
        onClick={() => {
          const route = getRoute(promoBlock)
          getDealContainer(route)
          setActiveDealContainer(route)
          changeTabBar('item')
        }}
        style={{
          background: `url(${promoBlock.image_url})`,
          backgroundSize: 'cover',
          width: '100%',
          height: 180,
          borderRadius: '.5rem',
        }} />

export default withConnect(MainBanner)
