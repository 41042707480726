import { createReducer } from 'redux-act'
import { setCategories } from '~/actions'
import { Category } from '~/models/api'

const defaultState = [] as Category[]
const reducer = createReducer({}, defaultState)

reducer.on(setCategories, (_, payload) => payload)

export default reducer
export type State = typeof defaultState
