import React, { SFC } from 'react'
import { View, Panel, PanelHeader, Div, FormStatus } from '@vkontakte/vkui'

interface Props {
  id: string
}

const ErrorVkMessagesNotAllowed: SFC<Props> = ({ id }) =>
  <View id={id} activePanel="main">
    <Panel id="main">
      <PanelHeader>Не хватает прав</PanelHeader>
      <Div>
        <FormStatus title="Разрешите отправлять вам сообщения от группы" state="error">
          Мы не сможем предоставить вам информацию о купонах, если вы не разрешите отправлять вам личные сообщения.
        </FormStatus>
      </Div>
    </Panel>
  </View>

export default ErrorVkMessagesNotAllowed
